<template>
	<div>
		<nav>
			<div class="nav nav-tabs" id="nav-tab" role="tablist">
				<a
					v-for="(rs, i) in evaluation.requirement_steps"
					:key="i"
					class="nav-link nav-link-step"
					:class="{ active: mtd_requirementStepIsActive(evaluation.requirement_steps, rs) }"
					:id="`nav-group-${rs.id}-tab`"
					data-toggle="tab"
					:href="`#nav-group-${rs.id}`"
					role="tab"
					:aria-controls="`nav-group-${rs.id}`"
					:aria-selected="mtd_requirementStepIsActive(evaluation.requirement_steps, rs)"
					>{{ `Grupo ${rs.group_name}` }}</a
				>
			</div>
		</nav>
		<div class="tab-content" id="nav-tabContent">
			<div
				v-for="(rs, i) in evaluation.requirement_steps"
				:key="i"
				class="tab-pane fade pt-3"
				:class="{ 'show active': mtd_requirementStepIsActive(evaluation.requirement_steps, rs) }"
				:id="`nav-group-${rs.id}`"
				role="tabpanel"
				:aria-labelledby="`nav-group-${rs.id}-tab`">
				<template v-if="rs.current == 1">
					<!-- ETAPA ACTUAL -->
					<template v-if="mtd_getCurrentStepOfCurrentRequirementStep(rs).step_code == 'ASSIGNMENT_OF_EVALUATIONS'">
						<div class="row mb-2">
							<div class="col-md-12">
								<h4>Asignación de Evaluaciones</h4>
							</div>
						</div>
						<div v-if="isEditable" class="row mb-2">
							<div class="col-md-6 form-group">
								<label class="col-form-label">Selecciona las evaluaciones que rendirán los postulantes (Mínimo 2 evaluaciones)</label>
								<v-select v-model="selected_sheet" :options="requirements.sheets" label="evaluation_name" placeholder="Buscar la evaluación..." />
							</div>
							<div class="col-md-3 form-group align-self-end">
								<button @click="mtd_addSheet" :disabled="selected_sheet == null" class="btn btn-sm btn-info">Agregar</button>
							</div>
							<div class="col-md-3 form-group">
								<label class="col-form-label">Fecha límite de desarrollo</label>
								<flat-pickr
									v-model="evaluation_development_limit_date"
									:config="{
										minDate: new Date().fp_incr(1),
									}"
									class="form-control form-control-sm flatpickr-input"
									placeholder="Seleccionar"
									name="cessation_date"
									v-validate="'required'"
									data-vv-as="'Fecha de cese'"></flat-pickr>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<table class="table table-sm table-hover">
									<thead class="bg-success text-white">
										<tr>
											<th>Orden</th>
											<th>Tipo</th>
											<th>Ficha</th>
											<th>Quitar</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(sheet, i) in sheets" :key="i">
											<th v-text="i + 1"></th>
											<td v-text="sheet.is_form ? 'Formulario' : 'Evaluación'"></td>
											<td v-text="sheet.evaluation_name == null ? sheet.form_name : sheet.evaluation_name"></td>
											<td>
												<button v-if="sheet.required != 1" @click="mtd_removeSheet(sheet)" class="btn btn-xs btn-danger"><i class="fas fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div v-if="isEditable && requirement.state != 2" class="row">
							<div class="col-md-12 text-center">
								<button @click="mtd_assignSheets" :disabled="cp_disabledBtnSave" class="btn btn-sm btn-primary">Guardar <i class="fas fa-arrow-circle-right"></i></button>
							</div>
						</div>
					</template>
					<template v-else-if="mtd_getCurrentStepOfCurrentRequirementStep(rs).step_code == 'DEVELOPING_AND_RESULTS'">
						<div class="row mb-2">
							<div class="col-md-12 d-flex justify-content-between align-items-center">
								<h4>Postulantes</h4>
								<button class="btn btn-xs btn-secondary" data-toggle="collapse" data-target="#formCreateAddresss" aria-expanded="false" aria-controls="formCreateAddresss">
									Evaluaciones asignadas
								</button>
							</div>
						</div>
						<table class="table table-sm table-hover">
							<thead class="text-primary">
								<tr>
									<th>#</th>
									<th>Nombres</th>
									<th class="text-center">Prom. Evaluación</th>
									<th class="text-center">Pasa a la sig. etapa</th>
									<th class="text-center">Calificado</th>
									<th class="text-center">Acción</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(postulant, index) in postulants" :key="index">
									<th>{{ index + 1 }}</th>
									<td>
										<span @click="mtd_showViewPostulantModal" class="cursor-pointer"
											>{{ postulant.full_names }} <span v-if="postulant.retired" class="badge badge-danger ml-3">Retirado</span></span
										>
									</td>
									<td class="text-center">{{ postulant.sheet_configs.final_percentage ? postulant.sheet_configs.final_percentage + '%' : '---' }}</td>
									<td class="text-center" v-if="postulant.retired">--</td>
									<td class="text-center" v-if="postulant.retired == 0">
										<div v-if="postulant.sheet_configs.finished_at" class="form-check text-center">
											<input v-model="postulant.siguiente_etapa" id="defaultCheck1" type="checkbox" class="form-check-input form-check-input" value="" /><label
												for="defaultCheck1"
												class="form-check-label"></label>
										</div>
										<div class="text-center" v-else>---</div>
									</td>
									<td class="text-center">
										<i v-if="postulant.sheet_configs.finished_at" class="fas fa-check text-success cursor-pointer" style="font-size: 20px"></i>
										<i v-else class="fas fa-times text-danger cursor-pointer" style="font-size: 20px"></i>
									</td>
									<td class="text-center">
										<button v-if="postulant.retired == 0" @click="mtd_showSummaryModal(postulant)" class="btn btn-xs btn-warning mx-2"><i class="fas fa-book"></i> Resumen</button>
										<button
											v-if="postulant.retired == 0 && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
											v-tooltip.left="`Retirar postulante`"
											@click="mtd_togglePostulant(index, postulant, 1)"
											class="btn btn-xs btn-danger mx-2">
											<i class="fas fa-user-slash"></i>
										</button>
										<button
											v-if="postulant.retired && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
											v-tooltip.left="`Agregar postulante`"
											@click="mtd_togglePostulant(index, postulant, 0)"
											class="btn btn-xs btn-info mx-2">
											<i class="fas fa-user-plus"></i>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
						<!-- EVALUACIONES ASIGNADAS -->
						<div class="collapse mt-4" id="formCreateAddresss">
							<fieldset class="form-fieldset pb-0">
								<legend>Evaluaciones asignadas</legend>
								<div class="row">
									<div class="col-md-8 form-group">
										<li v-for="(sheet, i) in rs.steps.find(el => el.step_code == 'DEVELOPING_AND_RESULTS').requirement_steps[0].sheets" :key="i">
											{{ sheet.is_form == 1 ? `Formulario - ${sheet.form_name}` : `Evaluación - ${sheet.evaluation_name}` }}
										</li>
									</div>
									<div class="col-md-4 form-group">
										<label class="col-form-label">Fecha límite de desarrollo</label>
										<p>{{ rs.steps.find(el => el.step_code == 'DEVELOPING_AND_RESULTS').requirement_steps[0].sheet_config.limit_date_formated }}</p>
									</div>
								</div>
							</fieldset>
						</div>
						<div class="row mt-4" v-if="authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR' && requirement.state != 2">
							<div class="col-md-12 text-center">
								<button :disabled="cp_new_group" @click="mtd_newGroup" class="btn btn-sm btn-info mr-2"><i class="fas fa-plus"></i> Nuevo grupo</button>
								<button :disabled="!cp_new_group" @click="mtd_nextStep" class="btn btn-sm btn-primary">Ir a Entrevista Previa <i class="fas fa-arrow-circle-right"></i></button>
							</div>
						</div>
					</template>
				</template>
				<template v-else>
					<!-- ETAPA PASADA -->
					<div class="row mb-2">
						<div class="col-md-12 d-flex justify-content-between align-items-center">
							<h4>Postulantes</h4>
							<button class="btn btn-xs btn-secondary" data-toggle="collapse" data-target="#formCreateAddresss" aria-expanded="false" aria-controls="formCreateAddresss">
								Evaluaciones asignadas
							</button>
						</div>
					</div>
					<table class="table table-sm table-hover">
						<thead class="text-primary">
							<tr>
								<th>#</th>
								<th>Nombres</th>
								<th>Prom. Evaluación</th>
								<th class="text-center">Pasó a la sig. etapa?</th>
								<th class="text-center">Calificado</th>
								<th class="text-center">Acción</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(postulant, index) in rs.steps.find(el => el.step_code == 'DEVELOPING_AND_RESULTS').requirement_steps[0].postulants" :key="index">
								<th>{{ index + 1 }}</th>
								<td>
									<span @click="mtd_showViewPostulantModal" class="cursor-pointer"
										>{{ postulant.full_names }} <span v-if="postulant.retired" class="badge badge-danger ml-3">Retirado</span></span
									>
								</td>
								<td>{{ postulant.sheet_configs.final_percentage ? postulant.sheet_configs.final_percentage + '%' : '---' }}</td>
								<td class="text-center" v-if="postulant.retired">--</td>
								<td class="text-center" v-if="postulant.retired == 0">
									<i v-if="postulant.selected" class="fas fa-check-square text-success cursor-pointer" style="font-size: 20px"></i>
									<i v-else class="fas fa-window-close text-danger cursor-pointer" style="font-size: 20px"></i>
								</td>
								<td class="text-center">
									<i v-if="postulant.sheet_configs.finished_at" class="fas fa-check text-success cursor-pointer" style="font-size: 20px"></i>
									<i v-else class="fas fa-times text-danger cursor-pointer" style="font-size: 20px"></i>
								</td>
								<td class="text-center">
									<button v-if="postulant.retired == 0" @click="mtd_showSummaryModal(postulant)" class="btn btn-xs btn-warning mx-2"><i class="fas fa-book"></i> Resumen</button>
								</td>
							</tr>
						</tbody>
					</table>
					<!-- EVALUACIONES ASIGNADAS -->
					<div class="collapse mt-4" id="formCreateAddresss">
						<fieldset class="form-fieldset pb-0">
							<legend>Evaluaciones asignadas</legend>
							<div class="row">
								<div class="col-md-8 form-group">
									<li v-for="(sheet, i) in rs.steps.find(el => el.step_code == 'DEVELOPING_AND_RESULTS').requirement_steps[0].sheets" :key="i">
										{{ sheet.is_form == 1 ? `Formulario - ${sheet.form_name}` : `Evaluación - ${sheet.evaluation_name}` }}
									</li>
								</div>
								<div class="col-md-4 form-group">
									<label class="col-form-label">Fecha límite de desarrollo</label>
									<p>{{ rs.steps.find(el => el.step_code == 'DEVELOPING_AND_RESULTS').requirement_steps[0].sheet_config.limit_date_formated }}</p>
								</div>
							</div>
						</fieldset>
					</div>
				</template>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'summary-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@showSheetModal="mtd_showSheetModal"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
			<template v-else-if="modal.id == 'sheet-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import SummaryModal from './components/Summary';
import SheetModal from './components/Sheet';

import { mapActions, mapGetters } from 'vuex';
export default {
	components: {
		SummaryModal,
		SheetModal,
	},
	props: ['propInfo', 'step_code'],
	data() {
		return {
			requirements: {
				sheets: [],
			},
			postulants: [],
			selected_sheet: null,
			evaluation_development_limit_date: null,
			sheets: [],
			modals: [
				{
					id: 'summary-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'summary-modal',
						idHash: '#summary-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
				{
					id: 'sheet-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'sheet-modal',
						idHash: '#sheet-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	computed: {
		...mapGetters({
			url_api: 'url_api',
			authenticated_user: 'authenticated_user',
			requirement: 'requirements_state/requirement',
			evaluation: 'requirements_state/evaluation',
			isEditable: 'requirements_state/isEditable',
			steps: 'requirements_state/steps',
		}),
		cp_disabledBtnSave() {
			if (this.sheets.length < 3) return true;
			if (this.evaluation_development_limit_date == null) return true;
			return false;
		},
		cp_new_group() {
			let state = true;
			let count = 0;
			this.postulants.forEach(postulant => {
				if (postulant.retired == 1) {
					count++;
				}
			});
			if (count == this.postulants.length) {
				state = false;
			}
			return state;
		},
	},
	watch: {
		evaluation() {
			this.mtd_initialData();
		},
	},
	created() {
		this.mtd_initialData();
	},
	methods: {
		...mapActions(['execute_commit', 'get', 'post', 'destroy']),
		mtd_initialData() {
			const current_requirement_step = this.evaluation.requirement_steps.find(el => el.current == 1);
			if (current_requirement_step) {
				const current_step = current_requirement_step.steps.find(el => el.current == 1);
				if (this.evaluation.current == 1 && current_step.step_code == 'ASSIGNMENT_OF_EVALUATIONS') {
					this.mtd_getListSheets();
				}
				if (this.evaluation.current == 1 && current_step.step_code == 'DEVELOPING_AND_RESULTS') {
					this.mtd_getPostulants();
				}
			}
		},
		// eslint-disable-next-line no-unused-vars
		mtd_componentSuccess: function ({ component, res, action, modal_id }) {
			switch (component) {
				case 'main-form':
					if (action == 'store') {
						this.mtd_getPostulants();
						//this.execute_commit({ mutation: 'USERS_INDEX__ADD_RESOURCE', payload: { user: res.user } });
					}
					if (action == 'update') {
						//this.execute_commit({ mutation: 'USERS_INDEX__UPDATE_RESOURCE', payload: { user: res.user } });
					}
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		mtd_getListSheets() {
			this.sheets = [];
			this.get({ url: `${this.url_api}/sheets/list?requirement_id=${this.requirement.id}` }).then(res => {
				if (res.state == 'success') {
					this.requirements.sheets = res.sheets.filter(el => el.is_evaluation == 1);
					//Seteo los formularios por defecto
					this.sheets.push(...res.sheets.filter(el => el.required == 1));
				}
			});
		},
		mtd_getPostulants() {
			this.get({ url: `${this.url_api}/postulants/list?requirement_id=${this.requirement.id}` }).then(res => {
				if (res.state == 'success') {
					this.postulants = res.postulants;
				}
			});
		},
		mtd_addSheet() {
			if (!this.sheets.find(el => el.id == this.selected_sheet.id)) {
				this.sheets.push(this.selected_sheet);
				this.selected_sheet = null;
			} else {
				window.message('info', 'Esta evaluación ya fue agregada');
			}
		},
		mtd_removeSheet(sheet) {
			this.sheets = this.sheets.filter(el => el.id != sheet.id);
		},
		mtd_assignSheets() {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro de asignar estas evaluaciones?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					const params = {
						evaluation_development_limit_date: this.evaluation_development_limit_date,
						sheets: this.sheets,
					};
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/requirement/${this.requirement.id}/assign/sheets`, params: params }).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `La asignación de evaluaciones fue exitosa`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
						});
					}
				});
		},
		mtd_showSummaryModal(postulant) {
			const main_form = this.modals.find(el => el.id == 'summary-modal');
			main_form.slot.component = 'summary-modal';
			main_form.slot.data = {
				row: {
					postulant: postulant,
				},
				method: 'create',
				buttonSubmit: 'Guardar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-lg';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fa fa-plus'></span> Resumen de la Evaluación";
		},
		mtd_showSheetModal(sheet) {
			const main_form = this.modals.find(el => el.id == 'sheet-modal');

			main_form.slot.component = 'sheet-modal';
			main_form.slot.data = {
				row: sheet,
				method: 'create',
				buttonSubmit: 'Cerrar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-right';
			main_form.config.title = "<span class='fa fa-plus'></span> Resumen de la Ficha";
		},
		/**
		 * METODOS DEL POSTULANTE
		 */
		mtd_showViewPostulantModal(row) {
			this.$emit('view-postulant', row);
		},
		mtd_togglePostulant(index, row, state) {
			let message = '';
			let button = '';
			let message_modal = '';
			if (state == 1) {
				message = '¿Esta seguro de retirar a este postulante?';
				message_modal = 'Retirado correctamente.';
				button = 'Si, retirar';
			}
			if (state == 0) {
				message = '¿Esta seguro que desea agregar a este postulante?';
				message_modal = 'Agregado correctamente.';
				button = 'Si, agregar';
			}
			this.$swal
				.fire({
					title: message,
					text: `${row.full_names}`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#e74c3c',
					confirmButtonText: button,
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/postulant/${row.postulation_step_id}/evaluation/retired`, params: { state: state } }).then(res => {
							if (res.state == 'success') {
								this.postulants[index].retired = state;
								window.message('success', message_modal);
							}
						});
					}
				});
		},
		/**
		 * FIN DE METODOS DEL POSTULANTE
		 */

		mtd_requirementStepIsActive(requirement_steps, rs) {
			if (requirement_steps.find(el => el.current == 1)) {
				return rs.current == 1 ? true : false;
			} else {
				return requirement_steps[requirement_steps.length - 1].id == rs.id ? true : false;
			}
		},
		mtd_getCurrentStepOfCurrentRequirementStep(rs) {
			return rs.steps.find(el => el.current == 1);
		},

		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		mtd_nextStep() {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro de pasar a la etapa de "Entrevista previa"?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/requirement/${this.requirement.id}/next_step_evaluation`, params: { step_code: this.step_code, postulants: this.postulants } }).then(
							res => {
								if (res.state == 'success') {
									this.$swal.fire({
										icon: 'success',
										title: `El Requerimiento pasó a la etapa de 'Entrevista previa'`,
										showCancelButton: false,
									});
									this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
									this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
								}
								if (res.state == 'exception') {
									window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
								}
							}
						);
					}
				});
		},
		mtd_newGroup: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea crear otro grupo?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/requirement/${this.requirement.id}/restart`,
							params: {},
						}).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `Grupo creado correctamente`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
							if (res.state == 'exception') {
								window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
							}
						});
					}
				});
		},
	},
};
</script>
