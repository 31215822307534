<template>
	<div>
		<nav>
			<div class="nav nav-tabs" id="nav-tab" role="tablist">
				<a
					v-for="(rs, i) in publication.requirement_steps"
					:key="i"
					class="nav-link nav-link-step"
					:class="{ active: mtd_requirementStepIsActive(publication.requirement_steps, rs) }"
					:id="`nav-group-${rs.id}-tab`"
					data-toggle="tab"
					:href="`#nav-group-${rs.id}`"
					role="tab"
					:aria-controls="`nav-group-${rs.id}`"
					:aria-selected="mtd_requirementStepIsActive(publication.requirement_steps, rs)"
					>{{ `Grupo ${rs.group_name}` }}</a
				>
			</div>
		</nav>
		<div class="tab-content" id="nav-tabContent">
			<div
				v-for="(rs, i) in publication.requirement_steps"
				:key="i"
				class="tab-pane fade pt-3"
				:class="{ 'show active': mtd_requirementStepIsActive(publication.requirement_steps, rs) }"
				:id="`nav-group-${rs.id}`"
				role="tabpanel"
				:aria-labelledby="`nav-group-${rs.id}-tab`">
				<template v-if="rs.current == 1">
					<!-- ETAPA ACTUAL -->
					<div class="row">
						<div class="col-md-12 mb-2">
							<h4>Links de convocatoria</h4>
						</div>
						<!-- LINKS DE LAS PLATAFORMAS-->
						<div v-for="(p, i) in platforms" :key="i" class="col-md-12 form-group">
							<label>Link de {{ p.name }}</label>
							<input
								v-model="p.link"
								type="text"
								class="form-control form-control-sm"
								:name="p.name"
								v-validate="{ url: { require_protocol: true }, required: true }"
								:data-vv-as="`'${p.name}'`"
								:disabled="!isEditable" />
							<span v-show="errors.has(p.name)" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first(p.name) }} </span>
							<ErrorBackendComponent :errorsBackend="errorsBackend" :name="p.name" />
						</div>
						<!-- BUTTON DE ACCION -->
						<div v-if="isEditable && requirement.state != 2" class="col-md-12 mt-3 text-center">
							<button @click="mtd_send('this.mtd_storeLinks()')" class="btn btn-sm btn-primary">Guardar <i class="fas fa-arrow-circle-right"></i></button>
						</div>
					</div>
				</template>
				<template v-else>
					<!-- ETAPA PASADA -->
					<div class="row">
						<div class="col-md-12 mb-2">
							<h4>Links de convocatoria</h4>
						</div>
						<!-- LINKS DE LAS PLATAFORMAS-->
						<div v-for="(link, i) in rs.links" :key="i" class="col-md-12">
							<label>Link de {{ link.platform_name }}</label>
							<p>{{ link.url }}</p>
						</div>
					</div>
				</template>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'test'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	data() {
		return {
			platforms: [],
			//Data base
			errorsBackend: {},
			modals: [
				{
					id: 'test',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'test',
						idHash: '#test',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	computed: {
		...mapGetters({
			authenticated_user: 'authenticated_user',
			url_api: 'url_api',
			requirement: 'requirements_state/requirement',
			publication: 'requirements_state/publication',
			isEditable: 'requirements_state/isEditable',
		}),
	},
	watch: {
		publication() {
			this.mtd_initialData();
		},
	},
	created() {
		this.mtd_initialData();
	},
	methods: {
		...mapActions(['execute_commit', 'get', 'post']),
		mtd_initialData() {
			if (this.publication.current == 1) {
				this.mtd_createPublication();
			}
		},
		mtd_createPublication() {
			this.get({ url: `${this.url_api}/link/create` }).then(res => {
				if (res.state == 'success') {
					this.mtd_setPlatforms(res.platforms);
				}
			});
		},
		mtd_setPlatforms(platforms) {
			this.platforms = platforms.map(el => ({
				...el,
				link: null,
			}));
		},
		mtd_storeLinks() {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro de registrar la convocatoria?',
					text: 'Después de confirmar, pasarás a la etapa de Filtro Curricular',
					showCancelButton: true,
					confirmButtonText: 'Sí, registrar',
				})
				.then(result => {
					if (result.isConfirmed) {
						const params = {
							requirement_id: this.requirement.id,
							links: this.platforms.map(el => ({
								platform_id: el.id,
								url: el.link,
							})),
						};
						this.post({ url: `${this.url_api}/links/store`, params: params }).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: 'Convocatoria registrada con éxito',
									text: `A partir de ahora, encontrarás este requerimiento en la etapa de "En proceso"`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
						});
					}
				});
		},
		mtd_requirementStepIsActive(requirement_steps, rs) {
			if (requirement_steps.find(el => el.current == 1)) {
				return rs.current == 1 ? true : false;
			} else {
				return requirement_steps[requirement_steps.length - 1].id == rs.id ? true : false;
			}
		},

		mtd_componentSuccess({ component, res, action, modal_id }) {
			switch (component) {
				case 'main-form':
					if (action == 'store') {
						this.execute_commit({ mutation: 'USERS_INDEX__ADD_RESOURCE', payload: { user: res.user } });
					}
					if (action == 'update') {
						this.execute_commit({ mutation: 'USERS_INDEX__UPDATE_RESOURCE', payload: { user: res.user } });
					}
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		mtd_componentOnload(modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentError() {},
		mtd_closeModal(modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_send(method) {
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) _this.$eval(method);
			});
		},
	},
};
</script>
