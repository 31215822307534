<template>
	<div>
		<nav>
			<div class="nav nav-tabs" id="nav-tab" role="tablist">
				<a
					v-for="(rs, i) in closing.requirement_steps"
					:key="i"
					class="nav-link nav-link-step"
					:class="{ active: mtd_requirementStepIsActive(closing.requirement_steps, rs) }"
					:id="`nav-group-${rs.id}-tab`"
					data-toggle="tab"
					:href="`#nav-group-${rs.id}`"
					role="tab"
					:aria-controls="`nav-group-${rs.id}`"
					:aria-selected="mtd_requirementStepIsActive(closing.requirement_steps, rs)"
					>{{ `Grupo ${rs.group_name}` }}</a
				>
			</div>
		</nav>
		<div class="tab-content" id="nav-tabContent">
			<div
				v-for="(rs, i) in closing.requirement_steps"
				:key="i"
				class="tab-pane fade pt-3"
				:class="{ 'show active': mtd_requirementStepIsActive(closing.requirement_steps, rs) }"
				:id="`nav-group-${rs.id}`"
				role="tabpanel"
				:aria-labelledby="`nav-group-${rs.id}-tab`">
				<template v-if="rs.current == 1">
					<!-- ETAPA ACTUAL -->
					<div class="row mb-2">
						<div class="col-md-12">
							<h4>Postulantes</h4>
						</div>
					</div>
					<table class="table table-sm table-hover">
						<thead class="text-primary">
							<tr>
								<th>#</th>
								<th>Nombres</th>
								<th class="text-center">Pasa a la sig. etapa?</th>
								<th class="text-center">Contrato firmado?</th>
								<!-- <th class="text-center">Acción</th> -->
							</tr>
						</thead>
						<tbody>
							<tr v-for="(postulant, index) in rs.postulants" :key="index">
								<th>{{ index + 1 }}</th>
								<td>
									<span class="cursor-pointer">{{ postulant.full_names }}</span> <span v-if="postulant.retired" class="badge badge-danger ml-3">Retirado</span>
								</td>
								<!--PASA DE ETAPA-->
								<td class="text-center" v-if="postulant.finalist_state && postulant.retired == 0">
									<div class="form-check text-center" v-if="postulant.finalist.state && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'">
										<input v-model="postulant.siguiente_etapa" id="defaultCheck1" type="checkbox" class="form-check-input form-check-input" value="" /><label
											for="defaultCheck1"
											class="form-check-label"></label>
									</div>
									<template v-else>
										<div>---</div>
									</template>
								</td>
								<td class="text-center" v-else>---</td>
								<!--FIRMO CONTRATO -->
								<td class="text-center">
									<i v-if="postulant.finalist_state && postulant.finalist.state" class="fas fa-check-square text-success cursor-pointer" style="font-size: 20px"></i>
									<i v-else class="fas fa-window-close text-danger cursor-pointer" style="font-size: 20px"></i>
								</td>
								<!--ACCIONES-->
								<!-- <td v-if="postulant.finalist_state && postulant.finalist.state" class="text-center">
									<button @click="mtd_download(postulant)" class="btn btn-xs btn-primary mx-2"><i class="fas fa-marker"></i> Descargar contrato</button>
								</td>
								<td v-else class="text-center">---</td> -->
							</tr>
						</tbody>
					</table>
					<div class="row mt-4" v-if="authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR' && requirement.state != 2">
						<div class="col-md-12 text-center">
							<button @click="mtd_nextStep" class="btn btn-sm btn-primary">Ir a Finalizados <i class="fas fa-arrow-circle-right"></i></button>
						</div>
					</div>
				</template>
				<template v-else>
					<!-- ETAPA PASADA -->
					<div class="row mb-2">
						<div class="col-md-12">
							<h4>Postulantes</h4>
						</div>
					</div>
					<table class="table table-sm table-hover">
						<thead class="text-primary">
							<tr>
								<th class="text-center">#</th>
								<th>Nombres</th>
								<th class="text-center">Pasó a la sig. etapa?</th>
								<th class="text-center">Firmó contrato?</th>
								<!-- <th class="text-center">Acción</th> -->
							</tr>
						</thead>
						<tbody>
							<tr v-for="(postulant, index) in rs.postulants" :key="index">
								<th>{{ index + 1 }}</th>
								<td>
									<span class="cursor-pointer">{{ postulant.full_names }}</span> <span v-if="postulant.retired" class="badge badge-danger ml-3">Retirado</span>
								</td>
								<!--PASO DE ETAPA-->
								<td class="text-center">
									<i v-if="postulant.selected" class="fas fa-check-square text-success cursor-pointer" style="font-size: 20px"></i>
									<i v-else class="fas fa-window-close text-danger cursor-pointer" style="font-size: 20px"></i>
								</td>
								<!--FIRMO CONTRATO -->
								<td class="text-center">
									<i v-if="postulant.finalist_state && postulant.finalist.state" class="fas fa-check text-success cursor-pointer" style="font-size: 20px"></i>
									<i v-else class="fas fa-times text-danger cursor-pointer" style="font-size: 20px"></i>
								</td>
								<!--ACCIONES-->
								<!-- <td class="text-center">
									<button @click="mtd_download(postulant)" class="btn btn-xs btn-primary mx-2"><i class="fas fa-marker"></i> Descargar contrato</button>
								</td> -->
							</tr>
						</tbody>
					</table>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	props: ['propInfo', 'step_code'],
	data() {
		return {
			modals: [
				{
					id: 'medical-exam-programming-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'medical-exam-programming-modal',
						idHash: '#medical-exam-programming-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	created() {},
	computed: {
		...mapGetters({
			url_api: 'url_api',
			url_web: 'url_web',
			authenticated_user: 'authenticated_user',
			requirement: 'requirements_state/requirement',
			closing: 'requirements_state/closing',
			isEditable: 'requirements_state/isEditable',
			steps: 'requirements_state/steps',
		}),
	},
	methods: {
		...mapActions(['get', 'post', 'update', 'execute_commit']),
		mtd_requirementStepIsActive(requirement_steps, rs) {
			if (requirement_steps.find(el => el.current == 1)) {
				return rs.current == 1 ? true : false;
			} else {
				return requirement_steps[requirement_steps.length - 1].id == rs.id ? true : false;
			}
		},
		mtd_download: function (postulant) {
			window.open(`${this.url_web}/download/contract/${postulant.finalist.id}`);
		},
		mtd_nextStep() {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro de pasar a la etapa de "Finalizados"?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/requirement/${this.requirement.id}/next_step_evaluation`,
							params: { step_code: this.step_code, postulants: this.closing.requirement_steps.find(el => el.current == 1).postulants },
						}).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `El Requerimiento pasó a la etapa de 'Finalizados'`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
							if (res.state == 'exception') {
								window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
							}
						});
					}
				});
		},
	},
};
</script>
