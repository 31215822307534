<template>
	<div class="main-body" style="padding: 0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel">
					<template slot="button-extra">
						<button @click="mtd_back" class="btn btn-sm btn-secondary mr-2"><i class="fas fa-arrow-left"></i> Atrás</button>
						<button @click="mtd_showDetailsRequirementsModal" class="btn btn-sm btn-primary mr-2"><i class="fas fa-eye"></i> Ver detalles</button>
						<button @click="mtd_showTimelineModal" class="btn btn-sm btn-info"><i class="fas fa-clock"></i> Timeline</button>
					</template>
				</page-header>
				<div class="inner-main-body">
					<div class="card card-featured card-featured-primary">
						<div class="card-body">
							<!--NAVS	-->
							<nav>
								<div class="nav nav-tabs" id="nav-tab" role="tablist">
									<a
										v-for="(step, i) in steps"
										:key="i"
										class="nav-link nav-link-step"
										:class="{ active: step.current == 1 }"
										:id="`${step.step_code}-tab`"
										data-toggle="tab"
										:href="`#${step.step_code}`"
										role="tab"
										:aria-controls="`${step.step_code}`"
										:aria-selected="step.current == 1 ? true : false"
										>{{ step.step_name }}</a
									>
								</div>
							</nav>
							<div class="tab-content" id="nav-tabContent">
								<div
									v-for="(step, i) in steps"
									:key="i"
									class="tab-pane fade pt-3"
									:class="{ 'show active': step.current == 1 }"
									:id="`${step.step_code}`"
									role="tabpanel"
									:aria-labelledby="`${step.step_code}-tab`">
									<div>
										<template v-if="step.step_code == 'PUBLICATION'">
											<Publication />
										</template>
										<template v-else-if="step.step_code == 'CURRICULUM_FILTER'">
											<CurriculumFilter @emit-show-state="mtd_showState" />
										</template>
										<template v-else-if="step.step_code == 'EVALUATION'">
											<Evaluation :step_code="step.step_code" />
										</template>
										<template v-else-if="step.step_code == 'PREVIOUS_INTERVIEW'">
											<PreviousInterview :step_code="step.step_code" @emit-show-state="mtd_showState" />
										</template>
										<template v-else-if="step.step_code == 'PRINCIPAL_INTERVIEW'">
											<PrincipalInterview @emit-show-state="mtd_showState" />
										</template>
										<template v-else-if="step.step_code == 'MEDICAL_EXAM'">
											<MedicalExam :step_code="step.step_code" @emit-show-state="mtd_showState" />
										</template>
										<template v-else-if="step.step_code == 'CLOSING'">
											<Closing :step_code="step.step_code" />
										</template>
										<template v-else-if="step.step_code == 'FINISHED' && step.postulants.length > 0">
											<Finished :step_code="step.step_code" />
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'details-requirement-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
			<template v-if="modal.id == 'timeline-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import DetailsRequirementModal from '@/pages/shared/requirements/Details.vue';
import TimelineModal from '@/pages/shared/requirements/Timeline.vue';

import Publication from './publication/Publication';
import CurriculumFilter from './curriculum_filter/CurriculumFilter';
import Evaluation from './evaluation/Evaluation';
import PreviousInterview from './previous_interview/PreviousInterview';
import PrincipalInterview from './principal_interview/PrincipalInterview';
import MedicalExam from './medical_exam/MedicalExam';
import Closing from './closing/Closing';
import Finished from './finished/Finished';
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['requirement_number'],
	components: {
		DetailsRequirementModal,
		TimelineModal,
		Publication,
		CurriculumFilter,
		Evaluation,
		PreviousInterview,
		PrincipalInterview,
		MedicalExam,
		Closing,
		Finished,
	},
	data() {
		return {
			//Data base
			dataModel: {
				alias: `Requerimiento ${this.requirement_number}`,
				icon: 'fas fa-book-reader',
			},
			page: {
				header: {
					menu: ['Estado'],
				},
				buttons: {
					create: false,
					import: false,
					export: false,
				},
			},
			modals: [
				{
					id: 'details-requirement-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'details-requirement-modal',
						idHash: '#details-requirement-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
				{
					id: 'postulant-main-form',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'postulant-main-form',
						idHash: '#postulant-main-form',
						size: null,
						scroll: true,
						position: '',
					},
				},
				{
					id: 'postulant-massive-load-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'postulant-massive-load-modal',
						idHash: '#postulant-massive-load-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
				//timeline
				{
					id: 'timeline-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'timeline-modal',
						idHash: '#timeline-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	computed: {
		...mapGetters({
			url_api: 'url_api',
			requirement: 'requirements_state/requirement',
			steps: 'requirements_state/steps',
			publication: 'requirements_state/publication',
			authenticated_user: 'authenticated_user',
		}),
	},
	created() {
		this.mtd_showState();
	},
	methods: {
		...mapActions(['get', 'execute_commit']),
		mtd_back: function () {
			if (this.authenticated_user.roles[0].code == 'REQUIREMENT_RESPONSABLE') {
				this.$router.push({ name: 'requirement_responsable-requirements-index' });
			}
			if (this.authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR') {
				this.$router.push({ name: 'selection_administrator-requirements-index' });
			}
			if (this.authenticated_user.roles[0].code == 'ADMINISTRATOR') {
				this.$router.push({ name: 'administrator-requirements-index' });
			}
			if (this.authenticated_user.roles[0].code == 'GENERAL_ADMINISTRATOR') {
				this.$router.push({ name: 'general_administrator-requirements-index' });
			}
		},
		mtd_componentSuccess({ component, res, action, modal_id }) {
			switch (component) {
				case 'main-form':
					if (action == 'store') {
						this.execute_commit({ mutation: 'USERS_INDEX__ADD_RESOURCE', payload: { user: res.user } });
					}
					if (action == 'update') {
						this.execute_commit({ mutation: 'USERS_INDEX__UPDATE_RESOURCE', payload: { user: res.user } });
					}
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		mtd_showState() {
			this.get({ url: `${this.url_api}/requirement/${this.requirement_number}/show/state` }).then(res => {
				if (res.state == 'success') {
					this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
					this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
				}
			});
		},

		/**
		 * METODOS DETALLES DEL REQUERIMIENTO
		 */
		mtd_showTimelineModal: function () {
			const main_form = this.modals.find(el => el.id == 'timeline-modal');

			main_form.slot.component = 'timeline-modal';
			main_form.slot.data = {
				row: this.steps,
				method: 'view',
				buttonSubmit: 'Cerrar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-lg';
			main_form.config.position = 'modal-center';
			main_form.config.title = `<span class='fas fa-book-reader'></span> Linea de tiempo del requerimiento ${this.requirement.requirement_number}`;
		},
		mtd_showDetailsRequirementsModal() {
			const main_form = this.modals.find(el => el.id == 'details-requirement-modal');
			main_form.slot.component = 'details-requirement-modal';
			main_form.slot.data = {
				row: this.requirement,
				method: 'view',
				buttonSubmit: 'Cerrar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-lg';
			main_form.config.position = 'modal-left';
			main_form.config.title = `<span class='fas fa-book-reader'></span> Requerimiento ${this.requirement.requirement_number}`;
		},

		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
	},
};
</script>
