<template>
	<div class="modal-body">
		<!-- NOMBRE -->
		<div class="row">
			<div class="col-md-12 form-group">
				<label class="col-form-label">Subir archivo</label>
				<div class="custom-file">
					<input
						@change="handleFileUpload()"
						class="form-control form-control-sm"
						type="file"
						name="file"
						id="file"
						ref="file"
						v-validate="'required|ext:xls,xlsx'"
						data-vv-as="'Excel'"
						lang="es" />
				</div>
				<span v-show="errors.has('file')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('file') }} </span>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-sm btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_plantilla" class="btn btn-sm btn-info mr-3">Descargar plantilla</button>
				<button @click="mtd_close" class="btn btn-sm btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			file: null,
			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},

	computed: {
		...mapGetters({
			url_api: 'url_api',
			url_web: 'url_web',
			requirement: 'requirements_state/requirement',
		}),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'postWithFile']),
		mtd_onCreate() {
			this.$emit('onload', { status: 'success' });
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
		handleFileUpload() {
			let file_new = this.$refs.file.files;
			this.file = file_new[0];
		},
		mtd_plantilla: function () {
			window.open(`${this.url_web}/download/plantilla_postulantes`);
		},
		mtd_store: function () {
			this.$swal
				.fire({
					icon: 'warning',
					title: '¿Está seguro que desea registrar?',
					showCancelButton: true,
					confirmButtonText: 'Si, enviar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.formData = new FormData();
						this.formData.append('requirement_id', this.requirement.id);
						this.formData.append('file', this.file);
						this.postWithFile({
							url: `${this.url_api}/postulant/store_masive`,
							formData: this.formData,
						}).then(res => {
							if (res.state == 'success') {
								this.$emit('success', {
									component: 'postulant-massive-load-modal',
									data: {},
									action: 'store',
									modal_id: 'postulant-massive-load-modal',
								});
								this.$swal.fire({
									icon: 'success',
									title: 'Enviado',
									text: `Postulantes registrados correctamente.`,
									showCancelButton: false,
								});
							}
							if (res.state == 'exception') {
								window.simplealert('error', res.message, '<i class="fas fa-bomb"></i> Error interno');
							}
						});
					}
				});
		},
	},
};
</script>
