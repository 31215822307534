<template>
	<div>
		<div class="row mb-2">
			<div class="col-md-12">
				<h4>Postulantes finalistas</h4>
			</div>
		</div>
		<table class="table table-sm table-hover">
			<thead class="text-primary">
				<tr>
					<th>#</th>
					<th>Documento</th>
					<th>Nombres</th>
					<th class="text-center">Puesto</th>
					<th class="text-center">Sede</th>
					<th class="text-center">Correo</th>
					<th class="text-center">Celular</th>
				</tr>
			</thead>
			<tbody v-for="(postulant, index) in finished.postulants" :key="index">
				<tr>
					<td>{{ index + 1 }}</td>
					<td>{{ postulant.postulant_type_document }} - {{ postulant.postulant_document }}</td>
					<td>{{ postulant.postulant_names }}</td>
					<td class="text-center">{{ requirement.job_position_name }}</td>
					<td class="text-center">{{ requirement.campu_name }}</td>
					<td class="text-center">{{ postulant.postulant_email }}</td>
					<td class="text-center">{{ postulant.postulant_cellphone }}</td>
				</tr>
			</tbody>
		</table>
		<div class="row mb-2">
			<div class="col-md-12">
				<span class="badge badge-info mr-2">Total de vacantes solicitadas: {{ requirement.vacancies_number }}</span>
				<span class="badge badge-primary mr-2">Vacantes cubiertas: {{ finished.postulants.length }}</span>
				<span class="badge badge-warning mr-2">Vacantes desiertas: {{ requirement.vacancies_number - finished.postulants.length }}</span>
			</div>
		</div>
		<div class="row mt-4" v-if="!requirement.state && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'">
			<div class="col-md-12 text-center">
				<button :disabled="requirement.vacancies_number - finished.postulants.length == 0" @click="mtd_newGroup" class="btn btn-sm btn-info mr-2">
					<i class="fas fa-plus"></i> Nuevo grupo
				</button>
				<button @click="mtd_finish" class="btn btn-sm btn-primary"><i class="fas fa-thumbs-up"></i> Finalizar requerimiento</button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			url_api: 'url_api',
			url_web: 'url_web',
			authenticated_user: 'authenticated_user',
			requirement: 'requirements_state/requirement',
			finished: 'requirements_state/finished',
		}),
	},
	methods: {
		...mapActions(['get', 'post', 'execute_commit']),
		mtd_newGroup: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea crear otro grupo?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/requirement/${this.requirement.id}/restart`,
							params: {},
						}).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `Grupo creado correctamente`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
							if (res.state == 'exception') {
								window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
							}
						});
					}
				});
		},
		mtd_finish: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea finalizar el requerimiento?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/requirement/${this.requirement.id}/finished`,
							params: {},
						}).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `Requerimiento finalizado correctamente`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
							}
							if (res.state == 'exception') {
								window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
							}
						});
					}
				});
		},
	},
};
</script>
