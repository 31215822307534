<template>
	<div class="modal-body form form-horizontal">
		<!-- CREAR -->
		<div class="row">
			<!-- DATOS DEL POSTULANTE -->
			<div class="col-md-12">
				<h5>Datos del postulante</h5>
			</div>
			<!-- TIPO DE DOC. -->
			<div class="col-md-6">
				<label class="col-form-label label-modal" for="business_id">Tipo de Doc.</label>
				<p>{{ propInfo.data.row.document_type_name }}</p>
			</div>
			<!-- NUMERO DE DOCUMENTO -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Número de Doc.</label>
				<p>{{ propInfo.data.row.document_number }}</p>
			</div>
			<!-- NOMBRES COMPLETOS -->
			<div class="col-md-12">
				<label class="col-form-label label-modal">Apellidos y nombres</label>
				<p>{{ propInfo.data.row.full_names }}</p>
			</div>
			<!-- CELULAR -->
			<div class="col-md-4">
				<label class="col-form-label label-modal">Celular</label>
				<p>{{ propInfo.data.row.cellphone }}</p>
			</div>
			<!-- EMAIL -->
			<div class="col-md-8">
				<label class="col-form-label label-modal">Correo</label>
				<p>{{ propInfo.data.row.email }}</p>
			</div>
			<template v-if="propInfo.data.method == 'definition'">
				<!-- PROGRAMACION -->
				<div class="col-md-12 mt-3">
					<h5>Programación de la entrevista</h5>
				</div>
				<!-- FECHA Y HORA -->
				<div class="col-md-12 form-group">
					<label class="col-form-label label-modal">Fecha y hora</label>
					<flat-pickr
						v-model="date"
						:config="{
							minDate: new Date(),
							enableTime: true,
							time_24hr: false,
							dateFormat: 'Y-m-d H:i:S',
							altFormat: 'j F, Y - G:i K',
						}"
						class="form-control form-control-sm flatpickr-input"
						placeholder="Seleccionar"
						name="test"
						v-validate="'required'"
						data-vv-as="'Fecha y hora de la entrevista'"></flat-pickr>
					<span v-show="errors.has('test')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('test') }} </span>
				</div>
			</template>
			<template v-if="propInfo.data.method == 'update'">
				<!-- PROGRAMACION -->
				<div class="col-md-12 mt-3">
					<h5>Reprogramación de la entrevista</h5>
				</div>
				<!-- FECHA Y HORA -->
				<div class="col-md-12 form-group">
					<label class="col-form-label label-modal">Fecha y hora</label>
					<flat-pickr
						v-model="date"
						:config="{
							minDate: new Date(),
							enableTime: true,
							time_24hr: false,
							dateFormat: 'Y-m-d H:i:S',
							altFormat: 'j F, Y - G:i K',
						}"
						class="form-control form-control-sm flatpickr-input"
						placeholder="Seleccionar"
						name="test"
						v-validate="'required'"
						data-vv-as="'Fecha y hora de la entrevista'"></flat-pickr>
					<span v-show="errors.has('test')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('test') }} </span>
				</div>
			</template>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {},
			date: null,

			errorsBackend: {},
			methods: {
				init: {
					definition: 'this.mtd_onDefinition()',
					update: 'this.mtd_onDefinition()',
				},
				action: {
					definition: 'this.mtd_store()',
					update: 'this.mtd_update()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onDefinition() {
			this.mtd_definition();
		},
		mtd_definition() {
			this.$emit('onload', { status: 'success' });
		},
		mtd_store: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea programar la Entrevista Principal?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/postulant/${this.propInfo.data.row.postulation_step_id}/interview_store`, params: { date: this.date, type: 'PRINCIPAL' } }).then(
							res => {
								if (res.state == 'success') {
									this.$emit('success', {
										component: 'principal-interview-programming-modal',
										data: { i: this.propInfo.data.i, index: this.propInfo.data.index, interview: res.interview },
										action: 'store',
										modal_id: 'principal-interview-programming-modal',
									});
									this.$swal.fire({
										icon: 'success',
										title: `Fecha registrada correctamente para la 'Entrevista Principal'`,
										showCancelButton: false,
									});
								}
							}
						);
					}
				});
		},
		mtd_update: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea reprogramar la Entrevista Principal?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/postulant/${this.propInfo.data.row.postulation_step_id}/interview_update`,
							params: { date: this.date, interview_id: this.propInfo.data.row.interview.id, type: 'PRINCIPAL' },
						}).then(res => {
							if (res.state == 'success') {
								this.$emit('success', {
									component: 'principal-interview-programming-modal',
									data: { i: this.propInfo.data.i, index: this.propInfo.data.index, interview: res.interview },
									action: 'store',
									modal_id: 'principal-interview-programming-modal',
								});
								this.$swal.fire({
									icon: 'success',
									title: `Fecha registrada correctamente para la 'Entrevista principal'`,
									showCancelButton: false,
								});
							}
						});
					}
				});
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
	},
};
</script>
