<template>
	<div class="modal-body form form-horizontal">
		<!-- CREAR -->
		<div v-if="propInfo.data.method == 'definition'" class="row">
			<!-- FECHA Y HORA -->
			<div class="col-md-12 form-group">
				<label class="col-form-label label-modal">Fecha y hora</label>
				<flat-pickr
					v-model="date"
					:config="{
						minDate: new Date(),
						enableTime: true,
						time_24hr: false,
						dateFormat: 'Y-m-d H:i:S',
						altFormat: 'j F, Y - G:i K',
					}"
					class="form-control form-control-sm flatpickr-input"
					placeholder="Seleccionar"
					name="test"
					v-validate="'required'"
					data-vv-as="'Fecha y hora de la entrevista'"></flat-pickr>
				<span v-show="errors.has('test')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('test') }} </span>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {},
			date: null,

			errorsBackend: {},
			methods: {
				init: {
					definition: 'this.mtd_onDefinition()',
				},
				action: {
					definition: 'this.mtd_store()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onDefinition() {
			this.mtd_definition();
		},
		mtd_definition() {
			this.$emit('onload', { status: 'success' });
		},
		mtd_store: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea programar la Entrevista Principal?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/postulant/interview_block`, params: { date: this.date, postulants: this.propInfo.data.row, type: 'PRINCIPAL' } }).then(res => {
							if (res.state == 'success') {
								this.$emit('success', {
									component: 'principal-programming-block-modal',
									data: {},
									action: 'store',
									modal_id: 'principal-programming-block-modal',
								});
								this.$swal.fire({
									icon: 'success',
									title: `Fechas registradas correctamente para la 'Entrevista Principal'`,
									showCancelButton: false,
								});
							}
						});
					}
				});
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
	},
};
</script>
