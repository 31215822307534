<template>
	<div>
		<nav>
			<div class="nav nav-tabs" id="nav-tab" role="tablist">
				<a
					v-for="(rs, i) in curriculum_filter.requirement_steps"
					:key="i"
					class="nav-link nav-link-step"
					:class="{ active: mtd_requirementStepIsActive(curriculum_filter.requirement_steps, rs) }"
					:id="`nav-group-${rs.id}-tab`"
					data-toggle="tab"
					:href="`#nav-group-${rs.id}`"
					role="tab"
					:aria-controls="`nav-group-${rs.id}`"
					:aria-selected="mtd_requirementStepIsActive(curriculum_filter.requirement_steps, rs)"
					>{{ `Grupo ${rs.group_name}` }}</a
				>
			</div>
		</nav>
		<div class="tab-content" id="nav-tabContent">
			<div
				v-for="(rs, i) in curriculum_filter.requirement_steps"
				:key="i"
				class="tab-pane fade pt-3"
				:class="{ 'show active': mtd_requirementStepIsActive(curriculum_filter.requirement_steps, rs) }"
				:id="`nav-group-${rs.id}`"
				role="tabpanel"
				:aria-labelledby="`nav-group-${rs.id}-tab`">
				<template v-if="rs.current == 1">
					<!-- ETAPA ACTUAL -->
					<div class="row">
						<div class="col-md-12 mb-2">
							<h4>
								Postulantes
								<div v-if="isEditable && requirement.state != 2" class="d-inline">
									<i @click="mtd_showCreatePostulantModal" class="fas fa-plus-circle text-primary cursor-pointer mx-1"></i>
									<i @click="mtd_showMassiveLoadPostulantModal" class="fas fa-cloud-upload-alt text-success cursor-pointer mx-1"></i>
								</div>
							</h4>
						</div>
						<template v-if="postulants.length">
							<div class="col-md-12">
								<table class="table table-sm table-hover">
									<thead class="text-primary">
										<tr>
											<th>#</th>
											<th>Documento</th>
											<th>Nombres</th>
											<th>Correo</th>
											<th>Celular</th>
											<th>Fuente</th>
											<th v-if="isEditable" class="text-center">Acción</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(p, i) in postulants" :key="i">
											<td v-text="i + 1"></td>
											<td v-text="`${p.document_type_name} - ${p.document_number}`"></td>
											<td>
												<span @click="mtd_showViewPostulantModal(p)" class="cursor-pointer">{{ p.full_names }}</span>
											</td>
											<td v-text="p.email"></td>
											<td v-text="p.cellphone"></td>
											<td v-text="p.source_name"></td>
											<td v-if="isEditable" class="text-center">
												<button @click="mtd_showEditPostulantModal(p)" class="btn btn-xs btn-warning mx-2"><i class="fas fa-edit"></i></button>
												<button @click="mtd_removePostulantModal(p)" class="btn btn-xs btn-danger mx-2"><i class="fas fa-trash"></i></button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</template>
						<template v-else>
							<div class="col-md-12 mt-3">
								<p class="text-center">No existen postulantes registrados</p>
							</div>
						</template>
						<div v-if="isEditable && requirement.state != 2" class="col-md-12 text-center mt-4">
							<button @click="mtd_nextStep" :disabled="cp_disabledBtnNextStep" class="btn btn-sm btn-primary">Ir a Evaluación <i class="fas fa-arrow-circle-right"></i></button>
						</div>
					</div>
				</template>
				<template v-else>
					<!-- ETAPA PASADA -->
					<div class="row">
						<div class="col-md-12 d-flex justify-content-between mb-2">
							<h4>Postulantes</h4>
						</div>
						<div class="col-md-12">
							<table class="table table-sm table-hover">
								<thead class="text-primary">
									<tr>
										<th>#</th>
										<th>Documento</th>
										<th>Nombres</th>
										<th>Correo</th>
										<th>Celular</th>
										<th>Fuente</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(p, i) in rs.postulants" :key="i">
										<td v-text="i + 1"></td>
										<td v-text="`${p.document_type_name} - ${p.document_number}`"></td>
										<td>
											<span @click="mtd_showViewPostulantModal(p)" class="cursor-pointer">{{ p.full_names }}</span>
										</td>
										<td v-text="p.email"></td>
										<td v-text="p.cellphone"></td>
										<td v-text="p.source_name"></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</template>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'curriculum-filter-postulant-main-form'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
			<template v-else-if="modal.id == 'postulant-massive-load-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import PostulantMainForm from '../shared/postulant/MainForm';
import PostulantMassiveLoadModal from '../shared/postulant/MassiveLoad';

import { mapActions, mapGetters } from 'vuex';
export default {
	components: {
		PostulantMainForm,
		PostulantMassiveLoadModal,
	},
	data() {
		return {
			postulants: [],

			modals: [
				{
					id: 'curriculum-filter-postulant-main-form',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'curriculum-filter-postulant-main-form',
						idHash: '#curriculum-filter-postulant-main-form',
						size: null,
						scroll: true,
						position: '',
					},
				},
				{
					id: 'postulant-massive-load-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'postulant-massive-load-modal',
						idHash: '#postulant-massive-load-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	computed: {
		...mapGetters({
			url_api: 'url_api',
			requirement: 'requirements_state/requirement',
			curriculum_filter: 'requirements_state/curriculum_filter',
			isEditable: 'requirements_state/isEditable',
		}),
		cp_disabledBtnNextStep() {
			if (!this.postulants.length) return true;
			return false;
		},
	},
	watch: {
		curriculum_filter() {
			this.mtd_initialData();
		},
	},
	created() {
		this.mtd_initialData();
	},
	methods: {
		...mapActions(['execute_commit', 'get', 'post', 'destroy']),
		mtd_initialData() {
			if (this.curriculum_filter.current == 1) {
				this.mtd_getListPostulants();
			}
		},
		mtd_getListPostulants() {
			this.get({ url: `${this.url_api}/postulants/list?requirement_id=${this.requirement.id}` }).then(res => {
				if (res.state == 'success') {
					this.postulants = res.postulants;
				}
			});
		},
		mtd_componentSuccess({ component, res, action, modal_id }) {
			switch (component) {
				case 'postulant-main-form':
					if (action == 'store') {
						this.postulants.push(res.postulant);
					} else if (action == 'update') {
						this.postulants = this.postulants.map(el => (el.id == res.postulant.id ? { ...res.postulant } : { ...el }));
					}
					this.mtd_closeModal(modal_id);
					break;
				case 'postulant-massive-load-modal':
					this.$emit('emit-show-state');
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		/**
		 * METODOS DEL POSTULANTE
		 */
		mtd_showCreatePostulantModal() {
			const main_form = this.modals.find(el => el.id == 'curriculum-filter-postulant-main-form');

			main_form.slot.component = 'postulant-main-form';
			main_form.slot.data = {
				row: null,
				method: 'create',
				buttonSubmit: 'Registrar postulante',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fa fa-plus'></span> Nuevo postulante";
		},
		mtd_showEditPostulantModal(row) {
			const main_form = this.modals.find(el => el.id == 'curriculum-filter-postulant-main-form');

			main_form.slot.component = 'postulant-main-form';
			main_form.slot.data = {
				row: row,
				method: 'edit',
				buttonSubmit: 'Editar postulante',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fa fa-edit'></span> Editando postulante";
		},
		mtd_showViewPostulantModal(row) {
			const main_form = this.modals.find(el => el.id == 'curriculum-filter-postulant-main-form');

			main_form.slot.component = 'postulant-main-form';
			main_form.slot.data = {
				row: row,
				method: 'view',
				buttonSubmit: 'Cerrar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fa fa-edit'></span> Datos del postulante";
		},
		mtd_removePostulantModal(row) {
			this.$swal
				.fire({
					title: '¿Esta seguro de retirar a este postulante?',
					text: `${row.full_names}`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#e74c3c',
					confirmButtonText: 'Si, retirar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.destroy({ url: `${this.url_api}/postulant/${row.postulation_step_id}/remove` }).then(res => {
							if (res.state == 'success') {
								this.postulants = this.postulants.filter(el => el.id != row.id);
								window.message('success', 'Eliminación exitosa');
							}
						});
					}
				});
		},
		mtd_showMassiveLoadPostulantModal() {
			const main_form = this.modals.find(el => el.id == 'postulant-massive-load-modal');

			main_form.slot.component = 'postulant-massive-load-modal';
			main_form.slot.data = {
				row: null,
				method: 'create',
				buttonSubmit: 'Cargar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fa fa-plus'></span> Carga masiva de postulantes";
		},
		mtd_nextStep() {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro de pasar a la etapa de "Evaluación"?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/requirement/${this.requirement.id}/next_step` }).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `El Requerimiento pasó a la etapa de 'Evaluación'`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
						});
					}
				});
		},

		mtd_requirementStepIsActive(requirement_steps, rs) {
			if (requirement_steps.find(el => el.current == 1)) {
				return rs.current == 1 ? true : false;
			} else {
				return requirement_steps[requirement_steps.length - 1].id == rs.id ? true : false;
			}
		},
		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		mtd_nextPagination: function (page) {
			this.dataTable.header.page = page;
		},
		mtd_initPagination: function () {
			this.dataTable.header.page = 0;
		},
	},
};
</script>
