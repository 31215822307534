<template>
	<div class="modal-body form form-horizontal">
		<!-- CREAR -->
		<div v-if="propInfo.data.method == 'definition'" class="row">
			<!-- TIPO DE EXAMEN MEDICO -->
			<div class="col-md-12 form-group">
				<label class="col-form-label label-modal">Tipo de examen médico</label>
				<select name="exam_type_id" v-model="exam_type_id" class="form-control form-control-sm" v-validate="'required'" data-vv-as="'Tipo de examen médico'">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in exam_types" :key="index">{{ item.name }}</option>
				</select>
				<span v-show="errors.has('exam_type_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('exam_type_id') }} </span>
			</div>
			<!-- CENTRO CLINICA -->
			<div class="col-md-12 form-group">
				<label class="col-form-label label-modal">Centro clinico</label>
				<select name="place_id" v-model="place_id" class="form-control form-control-sm" v-validate="'required'" data-vv-as="'Tipo de examen médico'">
					<option :value="null">Seleccionar</option>
					<option :value="item.id" v-for="(item, index) in places" :key="index">{{ item.name }}</option>
				</select>
				<span v-show="errors.has('place_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('place_id') }} </span>
			</div>
			<!-- FECHA Y HORA -->
			<div class="col-md-12 form-group">
				<label class="col-form-label label-modal">Fecha y hora</label>
				<flat-pickr
					v-model="date"
					:config="{
						minDate: new Date(),
						enableTime: true,
						time_24hr: false,
						dateFormat: 'Y-m-d H:i:S',
						altFormat: 'j F, Y - G:i K',
					}"
					class="form-control form-control-sm flatpickr-input"
					placeholder="Seleccionar"
					name="test"
					v-validate="'required'"
					data-vv-as="'Fecha y hora de la entrevista'"></flat-pickr>
				<span v-show="errors.has('test')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('test') }} </span>
			</div>
			<!-- ANTIGENICA -->
			<div class="col-md-4 form-group align-self-end">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" v-model="antigen" value="1" name="antigen" id="defaultCheck1" />
					<label class="form-check-label" for="defaultCheck1">Antígeno</label>
				</div>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {},
			exam_types: [],
			places: [],
			exam_type_id: null,
			place_id: null,
			date: null,
			antigen: null,

			errorsBackend: {},
			methods: {
				init: {
					definition: 'this.mtd_onDefinition()',
				},
				action: {
					definition: 'this.mtd_store()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onDefinition() {
			this.get({
				url: this.url_api + '/medical_exam/requirements',
			})
				.then(response => {
					this.exam_types = response.exam_types;
					this.places = response.places;
				})
				.catch(() => {});
			this.mtd_definition();
		},
		mtd_definition() {
			this.$emit('onload', { status: 'success' });
		},
		mtd_store: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea programar la Examen medico?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/medical_exam/store_block`,
							params: { exam_type_id: this.exam_type_id, place_id: this.place_id, date: this.date, antigen: this.antigen, postulants: this.propInfo.data.row },
						}).then(res => {
							if (res.state == 'success') {
								this.$emit('success', {
									component: 'medical-programming-block-modal',
									data: {},
									action: 'store',
									modal_id: 'medical-programming-block-modal',
								});
								this.$swal.fire({
									icon: 'success',
									title: `Fechas registradas correctamente para el 'Examen medico'`,
									showCancelButton: false,
								});
							}
						});
					}
				});
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
	},
};
</script>
