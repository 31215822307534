<template>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12">
				<label class="col-form-label">Nombres completos</label>
				<p>ERICK STALYN PACHERRES PUYEN</p>
			</div>
			<div class="col-md-12">
				<label class="col-form-label">Experiencia</label>
				<p>+ 4 años</p>
			</div>
			<div class="col-md-12">
				<label class="col-form-label">Carrear profesional</label>
				<p>Ingeniería de Sistemas</p>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {},
			sheet_type: 1,

			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onCreate() {
			this.$emit('onload', { status: 'success' });
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
	},
};
</script>
