<template>
	<div class="modal-body form form-horizontal">
		<!-- CREAR -->
		<div class="row">
			<div class="col-md-12">
				<label class="col-form-label text-primary"><i class="fas fa-angle-right"></i> Postulante</label>
				<p style="margin: 0">{{ this.propInfo.data.row.postulant.full_names }}</p>
			</div>
			<div class="col-md-12">
				<label class="col-form-label text-primary"><i class="fas fa-angle-right"></i> Información académica</label>
				<p style="margin: 0"><b>Grado de instrucción:</b> {{ study_centers.intruction_degree_name }}</p>
				<p style="margin: 0"><b>Centro de estudios:</b> {{ study_centers.name }}</p>
				<p style="margin: 0"><b>Carrera o curso:</b> {{ study_centers.career_school }}</p>
				<p style="margin: 0"><b>Estado:</b> {{ study_centers.state_name }}</p>
				<p v-if="study_centers.state == 2" style="margin: 0"><b>observación:</b> {{ study_centers.observation }}</p>
			</div>
		</div>
		<!-- FORMULARIOS Y EVALUACIONES -->
		<div class="row">
			<div class="col-md-12">
				<label class="col-form-label text-primary"><i class="fas fa-angle-right"></i> Evaluaciones y speechs</label>
				<div class="form-group" v-for="(sheet, index) in sheets" :key="index">
					<div class="row" v-if="sheet.is_form">
						<label class="col-12 col-sm-3 col-form-label">{{ sheet.form.name }}</label>
						<div v-if="sheet.is_finished" class="col-12 col-sm-2 align-self-center d-flex justify-content-center">
							<span class="badge badge-success"><i class="fas fa-check"></i> Completado</span>
						</div>
						<div v-if="sheet.is_started && !sheet.is_finished" class="col-12 col-sm-2 align-self-center d-flex justify-content-center">
							<span class="badge badge-dark"><i class="fas fa-check"></i> Por completar</span>
						</div>
						<div v-if="sheet.is_started == 0" class="col-12 col-sm-2 align-self-center d-flex justify-content-center">
							<span class="badge badge-danger"><i class="fas fa-check"></i> Sin iniciar</span>
						</div>
						<div v-if="sheet.is_finished" class="col-12 col-sm-2 align-self-center d-flex justify-content-center">
							<span @click="mtd_donwloadCv(sheet.form.postulant.curriculum_vitae)" class="badge badge-primary c-pointer"><i class="fas fa-check"></i> Descargar CV</span>
						</div>
					</div>
					<div class="row" v-if="sheet.is_evaluation">
						<label class="col-12 col-sm-4 col-form-label justify-content-center"
							>{{ sheet.evaluation.name }}
							<span @click="mtd_donwloadEvaluation(sheet.evaluation)" class="c-pointer badge badge-primary"><i v-if="sheet.is_finished" class="fas fa-download"></i> </span
						></label>
						<div
							v-if="sheet.is_finished && postulant_config.is_finished == 0 && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
							class="col-4 col-sm-2 align-self-center d-flex justify-content-center">
							<input
								placeholder="Promedio %"
								v-validate="'required'"
								:name="'porcentaje_' + sheet.requirement_sheet_id"
								data-vv-as="'Promedio'"
								v-model="sheet.porcentaje"
								type="text"
								class="form-control form-control-sm text-right" />
						</div>
						<div
							v-if="sheet.is_finished && postulant_config.is_finished == 0 && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
							class="col-8 col-sm-6 align-self-center d-flex justify-content-center">
							<input
								:name="'speech_' + sheet.requirement_sheet_id"
								v-validate="'required'"
								data-vv-as="'Speech'"
								v-model="sheet.speech"
								type="text"
								class="form-control form-control-sm"
								placeholder="Escriba el speech" />
						</div>
						<!--POR COMPLETAR -->
						<div v-if="sheet.is_started && !sheet.is_finished" class="col-12 col-sm-2 align-self-center d-flex justify-content-center">
							<span class="badge badge-dark"><i class="fas fa-check"></i> Por completar</span>
						</div>
						<!-- SIN INICIAR -->
						<div v-if="sheet.is_started == 0" class="col-12 col-sm-2 align-self-center d-flex justify-content-center">
							<span class="badge badge-danger"><i class="fas fa-check"></i> Sin iniciar</span>
						</div>
						<!------ EVALUACION FINALIZADA ------>
						<div v-if="postulant_config.is_finished == 1" class="col-4 col-sm-2 align-self-center d-flex justify-content-center">
							<input :value="sheet.percentage" readonly type="text" class="form-control form-control-sm text-right" />
						</div>
						<div v-if="postulant_config.is_finished == 1" class="col-8 col-sm-6 align-self-center d-flex justify-content-center">
							<input :value="sheet.speech" readonly type="text" class="form-control form-control-sm" placeholder="Escriba el speech" />
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-4 col-form-label">Promedio</label>
					<div v-if="postulant_config.is_finished == 1" class="col-2 align-self-center d-flex justify-content-center">{{ postulant_config.final_percentage }}%</div>
					<div class="col-2">
						<input
							v-if="postulant_config.is_finished == 0 && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
							type="text"
							class="form-control-plaintext form-control-sm text-right"
							readonly
							:value="promedio_final + '%'" />
					</div>
				</div>
			</div>
		</div>
		<!-- SPEECH GENERAL -->
		<div class="row">
			<div class="col-md-12">
				<label class="col-form-label">Speech General</label>
				<p v-if="postulant_config.is_finished == 1" v-html="postulant_config.general_speech"></p>
				<textarea
					v-if="postulant_config.is_finished == 0 && btn_guardar && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
					name="speech_general"
					v-validate="'required'"
					data-vv-as="'Speech General'"
					v-model="speech_general"
					class="form-control form-control-sm"
					placeholder="Escriba..."
					rows="4"></textarea>
				<span v-show="errors.has('speech_general')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('speech_general') }} </span>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button
					v-if="postulant_config.is_finished == 0 && btn_guardar && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
					@click="mtd_send"
					class="btn btn-primary mr-3">
					{{ propInfo.data.buttonSubmit }}
				</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {},
			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
			sheets: [],
			postulant_config: [],
			speech_general: '',
			study_centers: [],
		};
	},

	computed: {
		...mapGetters(['url_api', 'url_web', 'authenticated_user']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
		promedio_final: function () {
			let promedio = 0;
			let promedio_final = 0;
			let count_promedio = 0;
			this.sheets.forEach(sheet => {
				if (sheet.porcentaje) {
					count_promedio = count_promedio + 1;
					promedio = Number(promedio) + Number(sheet.porcentaje);
				}
			});
			if (promedio > 0) {
				promedio_final = promedio / count_promedio;
			} else {
				promedio_final = '-';
			}
			return promedio_final;
		},
		btn_guardar: function () {
			let state = true;
			let count = 0;
			this.sheets.forEach(sheet => {
				if (sheet.is_finished == 0) {
					count = count + 1;
				}
			});
			if (count == 0) {
				state = true;
			} else {
				state = false;
			}
			return state;
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onCreate() {
			this.post({
				url: `${this.url_api}/requirement/postulation/postulation_sheets`,
				params: {
					postulation_step_id: this.propInfo.data.row.postulant.postulation_step_id,
				},
			}).then(res => {
				this.sheets = res.sheets;
				this.postulant_config = res.postulant_config;
				this.study_centers = res.study_centers;
			});
			this.$emit('onload', { status: 'success' });
		},
		mtd_showSheetModal(sheet) {
			this.$emit('showSheetModal', sheet);
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
		mtd_store: function () {
			this.$swal
				.fire({
					icon: 'warning',
					title: '¿Está seguro que desea guardar?',
					showCancelButton: true,
					confirmButtonText: 'Si, guardar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/requirement/resume_evaluation/update`,
							params: {
								sheets: this.sheets,
								general_speech: this.speech_general,
								final_percentage: this.promedio_final,
								postulant_config_id: this.postulant_config.id,
							},
						}).then(respu => {
							if (respu.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: 'Enviado',
									text: `Evaluación guardada correctamente.`,
									showCancelButton: false,
								});
								this.$emit('success', { component: 'main-form', res: [], action: 'store', modal_id: 'summary-modal' });
							}
							if (respu.state == 'exception') {
								window.simplealert('error', respu.message, '<i class="fas fa-bomb"></i> Error interno');
							}
						});
					}
				});
		},
		mtd_donwloadCv: function (cv) {
			window.open(`${this.url_web}/uploads/cvs/${cv.uuid}.${cv.extension}`);
		},
		mtd_donwloadEvaluation: function (evaluation) {
			window.open(`${this.url_web}/postulation/evaluation/${evaluation.postulant_evaluation_id}/${this.propInfo.data.row.postulant.id}`);
		},
	},
};
</script>
