var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-body form form-horizontal"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label label-modal",attrs:{"for":"business_id"}},[_vm._v("Tipo de Doc.")]),_c('p',[_vm._v(_vm._s(_vm.propInfo.data.row.document_type_name))])]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"col-form-label label-modal"},[_vm._v("Número de Doc.")]),_c('p',[_vm._v(_vm._s(_vm.propInfo.data.row.document_number))])]),_c('div',{staticClass:"col-md-12"},[_c('label',{staticClass:"col-form-label label-modal"},[_vm._v("Apellidos y nombres")]),_c('p',[_vm._v(_vm._s(_vm.propInfo.data.row.full_names))])]),_c('div',{staticClass:"col-md-4"},[_c('label',{staticClass:"col-form-label label-modal"},[_vm._v("Celular")]),_c('p',[_vm._v(_vm._s(_vm.propInfo.data.row.cellphone))])]),_c('div',{staticClass:"col-md-8"},[_c('label',{staticClass:"col-form-label label-modal"},[_vm._v("Correo")]),_c('p',[_vm._v(_vm._s(_vm.propInfo.data.row.email))])]),(_vm.propInfo.data.method == 'definition')?[_vm._m(1),_c('div',{staticClass:"col-md-12 form-group"},[_c('label',{staticClass:"col-form-label label-modal"},[_vm._v("Fecha y hora")]),_c('flat-pickr',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control form-control-sm flatpickr-input",attrs:{"config":{
						minDate: new Date(),
						enableTime: true,
						time_24hr: false,
						dateFormat: 'Y-m-d H:i:S',
						altFormat: 'j F, Y - G:i K',
					},"placeholder":"Seleccionar","name":"test","data-vv-as":"'Fecha y hora de la entrevista'"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('test')),expression:"errors.has('test')"}],staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(_vm.errors.first('test'))+" ")])],1)]:_vm._e(),(_vm.propInfo.data.method == 'update')?[_vm._m(2),_c('div',{staticClass:"col-md-12 form-group"},[_c('label',{staticClass:"col-form-label label-modal"},[_vm._v("Fecha y hora")]),_c('flat-pickr',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control form-control-sm flatpickr-input",attrs:{"config":{
						minDate: new Date(),
						enableTime: true,
						time_24hr: false,
						dateFormat: 'Y-m-d H:i:S',
						altFormat: 'j F, Y - G:i K',
					},"placeholder":"Seleccionar","name":"test","data-vv-as":"'Fecha y hora de la entrevista'"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('test')),expression:"errors.has('test')"}],staticClass:"form-group__error"},[_c('i',{staticClass:"fas fa-exclamation-circle"}),_vm._v(" "+_vm._s(_vm.errors.first('test'))+" ")])],1)]:_vm._e()],2),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-primary mr-3",on:{"click":_vm.mtd_send}},[_vm._v(_vm._s(_vm.propInfo.data.buttonSubmit))]),_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.mtd_close}},[_vm._v("Cancelar")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12"},[_c('h5',[_vm._v("Datos del postulante")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 mt-3"},[_c('h5',[_vm._v("Programación de la entrevista")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-12 mt-3"},[_c('h5',[_vm._v("Reprogramación de la entrevista")])])}]

export { render, staticRenderFns }