<template>
	<div>
		<!--NAVS	-->
		<nav>
			<div class="nav nav-tabs" id="nav-tab" role="tablist">
				<a
					v-for="(rs, i) in medical_examen.requirement_steps"
					:key="i"
					class="nav-link nav-link-step"
					:class="{ active: mtd_requirementStepIsActive(medical_examen.requirement_steps, rs) }"
					:id="`nav-group-${rs.id}-tab`"
					data-toggle="tab"
					:href="`#nav-group-${rs.id}`"
					role="tab"
					:aria-controls="`nav-group-${rs.id}`"
					:aria-selected="mtd_requirementStepIsActive(medical_examen.requirement_steps, rs)"
					>{{ `Grupo ${rs.group_name}` }}</a
				>
			</div>
		</nav>

		<div class="tab-content" id="nav-tabContent">
			<div
				v-for="(rs, i) in medical_examen.requirement_steps"
				:key="i"
				class="tab-pane fade pt-3"
				:class="{ 'show active': mtd_requirementStepIsActive(medical_examen.requirement_steps, rs) }"
				:id="`nav-group-${rs.id}`"
				role="tabpanel"
				:aria-labelledby="`nav-group-${rs.id}-tab`">
				<template v-if="rs.current == 1">
					<!-- ETAPA ACTUAL -->
					<div class="row mb-2">
						<div class="col-md-12">
							<h4>Postulantes</h4>
						</div>
					</div>
					<table class="table table-sm table-hover">
						<thead class="text-primary">
							<tr>
								<th>#</th>
								<th v-if="authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'" class="text-center">Progra. en bloque?</th>
								<th>Nombres</th>
								<th class="text-center">Centro clínico</th>
								<th class="text-center">Fecha y hora</th>
								<th class="text-center">Resultado</th>
								<th class="text-center">Pasa a la sig. etapa?</th>
								<th class="text-center">Acción</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(postulant, index) in rs.postulants" :key="index">
								<th>{{ index + 1 }}</th>
								<!--PROGRAMAR EN GRUPO -->
								<template v-if="authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'">
									<td class="text-center" v-if="postulant.exam_state == 0 && !postulant.retired">
										<div class="form-check text-center">
											<input
												:value="postulant.postulation_step_id"
												v-model="selectedPostulants"
												:id="postulant.postulation_step_id"
												type="checkbox"
												class="form-check-input form-check-input" /><label :for="postulant.postulation_step_id" class="form-check-label"></label>
										</div>
									</td>
									<td class="text-center" v-else>---</td>
								</template>
								<!--APELLIDOS Y NOMBRES-->
								<td>
									<span class="cursor-pointer">{{ postulant.full_names }}</span> <span v-if="postulant.retired" class="badge badge-danger ml-3">Retirado</span>
								</td>
								<!--CENTRO CLINICO-->
								<td class="text-center" v-if="postulant.exam_state && postulant.retired == 0">{{ postulant.exam.place_name }}</td>
								<td class="text-center" v-else>---</td>
								<!--FECHA-->
								<td class="text-center" v-if="postulant.exam_state && postulant.retired == 0">{{ postulant.exam.scheduled_to }}</td>
								<td class="text-center" v-else>---</td>
								<!--RESULTADO-->
								<template v-if="postulant.exam.state && postulant.retired == 0">
									<td class="text-center">
										<span v-if="postulant.exam.suitable == 1" class="badge badge-primary">Apto</span>
										<span v-if="postulant.exam.suitable == 2" class="badge badge-warning">Apto con recomendaciones</span>
										<span v-if="postulant.exam.suitable == 0" class="badge badge-danger">No apto</span>
									</td>
								</template>
								<td class="text-center" v-else>---</td>
								<!--PASA DE ETAPA-->
								<td class="text-center" v-if="postulant.exam_state && postulant.retired == 0 && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'">
									<div class="form-check text-center" v-if="postulant.exam.suitable == 1 || postulant.exam.suitable == 2">
										<input v-model="postulant.siguiente_etapa" id="defaultCheck1" type="checkbox" class="form-check-input form-check-input" value="" /><label
											for="defaultCheck1"
											class="form-check-label"></label>
									</div>
									<template v-else>
										<div>---</div>
									</template>
								</td>
								<td class="text-center" v-else>---</td>
								<!--ACCIONES-->
								<td class="text-center">
									<button
										v-if="authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR' && !postulant.exam_state && !postulant.retired"
										@click="mtd_showProgrammingModal(postulant, i, index)"
										class="btn btn-xs btn-primary mx-2">
										<i class="far fa-calendar-alt"></i> Programación
									</button>
									<template v-if="postulant.exam_state && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR' && !postulant.retired">
										<button v-if="!postulant.exam.state" @click="mtd_showReprogrammingModal(postulant, i, index)" class="btn btn-xs btn-info mx-2">
											<i class="far fa-calendar-alt"></i> Reprogramación
										</button>
									</template>
									<button v-if="postulant.exam.state == 1" @click="mtd_showProgrammingResultsModal(postulant)" class="btn btn-xs btn-warning mx-2">
										<i class="fas fa-marker"></i> Resultados
									</button>
									<button
										v-if="postulant.retired == 0 && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
										v-tooltip.left="`Retirar postulante`"
										@click="mtd_togglePostulant(i, index, postulant, 1)"
										class="btn btn-xs btn-danger mx-2">
										<i class="fas fa-user-slash"></i>
									</button>
									<button
										v-if="postulant.retired && authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'"
										v-tooltip.left="`Agregar postulante`"
										@click="mtd_togglePostulant(i, index, postulant, 0)"
										class="btn btn-xs btn-info mx-2">
										<i class="fas fa-user-plus"></i>
									</button>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="row mt-4" v-if="requirement.state != 2">
						<div class="col-md-12 text-center" v-if="authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR'">
							<button @click="mtd_programarBloque" :disabled="selectedPostulants.length == 0" class="btn btn-sm btn-secondary mr-2">
								<i class="far fa-calendar-alt"></i> Programar en bloque
							</button>
							<button :disabled="cp_new_group" @click="mtd_newGroup" class="btn btn-sm btn-info mr-2"><i class="fas fa-plus"></i> Nuevo grupo</button>
							<button :disabled="!cp_new_group" @click="mtd_nextStep" class="btn btn-sm btn-primary">Ir a Cierre <i class="fas fa-arrow-circle-right"></i></button>
						</div>
					</div>
				</template>
				<template v-else>
					<!-- ETAPA PASADA -->
					<div class="row mb-2">
						<div class="col-md-12">
							<h4>Postulantes</h4>
						</div>
					</div>
					<table class="table table-sm table-hover">
						<thead class="text-primary">
							<tr>
								<th class="text-center">#</th>
								<th>Nombres</th>
								<th class="text-center">Centro clínico</th>
								<th class="text-center">Fecha y hora</th>
								<th class="text-center">Resultado</th>
								<th class="text-center">Paso a la sig. etapa?</th>
								<th class="text-center">Acción</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(postulant, index) in rs.postulants" :key="index">
								<th>{{ index + 1 }}</th>
								<td>
									<span class="cursor-pointer">{{ postulant.full_names }}</span> <span v-if="postulant.retired" class="badge badge-danger ml-3">Retirado</span>
								</td>
								<!--CENTRO CLINICO-->
								<td class="text-center" v-if="postulant.exam_state && postulant.retired == 0">{{ postulant.exam.place_name }}</td>
								<td class="text-center" v-else>---</td>
								<!--FECHA-->
								<td class="text-center" v-if="postulant.exam_state && postulant.retired == 0">{{ postulant.exam.scheduled_to }}</td>
								<td class="text-center" v-else>---</td>
								<!--RESULTADO-->
								<template v-if="postulant.exam.state && postulant.retired == 0">
									<td class="text-center">
										<span v-if="postulant.exam.suitable == 1" class="badge badge-primary">Apto</span>
										<span v-if="postulant.exam.suitable == 2" class="badge badge-warning">Apto con recomendaciones</span>
										<span v-if="postulant.exam.suitable == 0" class="badge badge-danger">No apto</span>
									</td>
								</template>
								<td class="text-center" v-else>---</td>
								<!--PASA DE ETAPA-->
								<td class="text-center" v-if="postulant.exam_state && postulant.retired == 0">
									<i v-if="postulant.selected" class="fas fa-check-square text-success cursor-pointer" style="font-size: 20px"></i>
									<i v-else class="fas fa-window-close text-danger cursor-pointer" style="font-size: 20px"></i>
								</td>
								<td class="text-center" v-else>---</td>
								<!--ACCIONES-->
								<td class="text-center">
									<button
										v-if="authenticated_user.roles[0].code == 'SELECTION_ADMINISTRATOR' && postulant.exam_state == 0"
										@click="mtd_showProgrammingModal(postulant, i, index)"
										class="btn btn-xs btn-primary mx-2">
										<i class="far fa-calendar-alt"></i> Programación
									</button>
									<button v-if="postulant.exam.state == 1" @click="mtd_showProgrammingResultsModal(postulant)" class="btn btn-xs btn-warning mx-2">
										<i class="fas fa-marker"></i> Resultados
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'medical-exam-programming-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
			<template v-else-if="modal.id == 'medical-exam-programming-results-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
			<template v-else-if="modal.id == 'medical-programming-block-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MedicalExamProgrammingModal from './components/Programming';
import MedicalExamProgrammingResultsModal from './components/ProgrammingResults';
import MedicalProgrammingBlockModal from './components/ProgrammingBlock';
export default {
	components: {
		MedicalExamProgrammingModal,
		MedicalExamProgrammingResultsModal,
		MedicalProgrammingBlockModal,
	},
	props: ['propInfo', 'step_code'],
	data() {
		return {
			selectedPostulants: [],

			modals: [
				{
					id: 'medical-exam-programming-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'medical-exam-programming-modal',
						idHash: '#medical-exam-programming-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
				{
					id: 'medical-exam-programming-results-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'medical-exam-programming-results-modal',
						idHash: '#medical-exam-programming-results-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
				//PROGRAMAR EN BLOQUE
				{
					id: 'medical-programming-block-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'medical-programming-block-modal',
						idHash: '#medical-programming-block-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	created() {},
	computed: {
		...mapGetters({
			url_api: 'url_api',
			authenticated_user: 'authenticated_user',
			requirement: 'requirements_state/requirement',
			medical_examen: 'requirements_state/medical_examen',
			isEditable: 'requirements_state/isEditable',
			steps: 'requirements_state/steps',
		}),
		cp_new_group() {
			let state = true;
			let count = 0;
			let postulants = this.medical_examen.requirement_steps.find(el => el.current == 1).postulants;
			postulants.forEach(postulant => {
				if (postulant.retired == 1) {
					count++;
				}
			});
			if (count == postulants.length) {
				state = false;
			}
			return state;
		},
	},
	methods: {
		...mapActions(['get', 'post', 'update', 'execute_commit']),
		// eslint-disable-next-line no-unused-vars
		mtd_componentSuccess: function ({ component, data, action, modal_id }) {
			switch (component) {
				case 'medical-exam-programming-modal':
					this.mtd_closeModal(modal_id);
					this.update_postulant(data, 1);
					break;
				case 'medical-programming-block-modal':
					this.$emit('emit-show-state');
					this.selectedPostulants = [];
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		mtd_showProgrammingModal(row, i, index) {
			const main_form = this.modals.find(el => el.id == 'medical-exam-programming-modal');

			main_form.slot.component = 'medical-exam-programming-modal';
			main_form.slot.data = {
				row: row,
				index: index,
				i: i,
				method: 'definition',
				buttonSubmit: 'Guardar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Programación del Examen Médico";
		},
		update_postulant: function (data, state) {
			if (state == 1) {
				this.medical_examen.requirement_steps[data.i].postulants[data.index].exam_state = 1;
			}
			this.medical_examen.requirement_steps[data.i].postulants[data.index].exam = data.exam;
		},
		mtd_programarBloque: function () {
			const main_form = this.modals.find(el => el.id == 'medical-programming-block-modal');
			main_form.slot.component = 'medical-programming-block-modal';
			main_form.slot.data = {
				row: this.selectedPostulants,
				method: 'definition',
				buttonSubmit: 'Guardar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-center';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Programación en bloque el Examen Medico";
		},
		mtd_showProgrammingResultsModal(row) {
			const main_form = this.modals.find(el => el.id == 'medical-exam-programming-results-modal');

			main_form.slot.component = 'medical-exam-programming-results-modal';
			main_form.slot.data = {
				row: row,
				method: 'definition',
				buttonSubmit: 'Cerrar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Resultados del Examen Médico";
		},
		mtd_showReprogrammingModal(row, i, index) {
			const main_form = this.modals.find(el => el.id == 'medical-exam-programming-modal');

			main_form.slot.component = 'medical-exam-programming-modal';
			main_form.slot.data = {
				row: row,
				index: index,
				i: i,
				method: 'update',
				buttonSubmit: 'Actualizar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Reprogramación del Examen Medico";
		},
		/**
		 * METODOS DEL POSTULANTE
		 */
		mtd_showViewPostulantModal(row) {
			this.$emit('view-postulant', row);
		},
		mtd_togglePostulant(i, index, row, state) {
			let message = '';
			let button = '';
			let message_modal = '';
			if (state == 1) {
				message = '¿Esta seguro de retirar a este postulante?';
				message_modal = 'Retirado correctamente.';
				button = 'Si, retirar';
			}
			if (state == 0) {
				message = '¿Esta seguro que desea agregar a este postulante?';
				message_modal = 'Agregado correctamente.';
				button = 'Si, agregar';
			}
			this.$swal
				.fire({
					title: message,
					text: `${row.full_names}`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#e74c3c',
					confirmButtonText: button,
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/postulant/${row.postulation_step_id}/evaluation/retired`, params: { state: state } }).then(res => {
							if (res.state == 'success') {
								this.medical_examen.requirement_steps[i].postulants[index].retired = state;
								window.message('success', message_modal);
							}
						});
					}
				});
		},
		/**
		 * FIN DE METODOS DEL POSTULANTE
		 */
		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},

		mtd_requirementStepIsActive(requirement_steps, rs) {
			if (requirement_steps.find(el => el.current == 1)) {
				return rs.current == 1 ? true : false;
			} else {
				return requirement_steps[requirement_steps.length - 1].id == rs.id ? true : false;
			}
		},
		mtd_nextStep() {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro de pasar a la etapa de "Cierre"?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/requirement/${this.requirement.id}/next_step_evaluation`,
							params: { step_code: this.step_code, postulants: this.medical_examen.requirement_steps.find(el => el.current == 1).postulants },
						}).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `El Requerimiento pasó a la etapa de 'Cierre'`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
							if (res.state == 'exception') {
								window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
							}
						});
					}
				});
		},
		mtd_newGroup: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea crear otro grupo?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/requirement/${this.requirement.id}/restart`,
							params: {},
						}).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `Grupo creado correctamente`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
							if (res.state == 'exception') {
								window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
							}
						});
					}
				});
		},
	},
};
</script>
