<template>
	<div class="modal-body">
		<!-- CREAR -->
		<div v-if="propInfo.data.method == 'create'" class="row">
			<!-- TIPO DE DOC. -->
			<div class="col-sm-6 col-md-7 form-group">
				<label class="col-form-label">Tipo de Doc.</label>
				<v-select
					v-model="postulant.person.document_type_id"
					:options="requirements.document_types"
					:reduce="dt => dt.id"
					label="name"
					name="document_type_id"
					v-validate="'required'"
					data-vv-as="'Tipo de Doc.'"
					placeholder="Seleccionar" />
				<span v-show="errors.has('document_type_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('document_type_id') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="document_type_id" />
			</div>
			<!-- NUMERO DE DOCUMENTO -->
			<div class="col-sm-6 col-md-5 form-group">
				<label class="col-form-label">Número de Doc.</label>
				<div class="input-group">
					<input
						v-model="postulant.person.document_number"
						type="text"
						class="form-control form-control-sm"
						name="document_number"
						v-validate="'required'"
						data-vv-as="'Num. de Doc.'" />
					<button @click="mtd_findPerson" :disabled="cpd_btnFindPersonDisabled" class="btn btn-xs btn-primary"><i class="fas fa-search"></i> Buscar</button>
					<span v-show="errors.has('document_number')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('document_number') }} </span>
					<ErrorBackendComponent :errorsBackend="errorsBackend" name="document_number" />
				</div>
			</div>
			<!-- NOMBRES -->
			<div class="col-md-12 form-group">
				<label class="col-form-label">Nombres</label>
				<input v-model="postulant.person.names" type="text" class="form-control form-control-sm text-uppercase" name="names" v-validate="'required'" data-vv-as="'Nombres'" />
				<span v-show="errors.has('names')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('names') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="names" />
			</div>
			<!-- APELLIDO PATERNO -->
			<div class="col-md-6 form-group">
				<label class="col-form-label">Apellido paterno</label>
				<input
					v-model="postulant.person.father_last_name"
					type="text"
					class="form-control form-control-sm text-uppercase"
					name="father_last_name"
					v-validate="'required'"
					data-vv-as="'Apellido paterno'" />
				<span v-show="errors.has('father_last_name')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('father_last_name') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="father_last_name" />
			</div>
			<!-- APELLIDO MATERNO -->
			<div class="col-md-6 form-group">
				<label class="col-form-label">Apellido materno</label>
				<input
					v-model="postulant.person.mother_last_name"
					type="text"
					class="form-control form-control-sm text-uppercase"
					name="mother_last_name"
					v-validate="'required'"
					data-vv-as="'Apellido materno'" />
				<span v-show="errors.has('mother_last_name')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('mother_last_name') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="mother_last_name" />
			</div>
			<!-- CORREO -->
			<div class="col-md-12 form-group">
				<label class="col-form-label">Correo</label>
				<input v-model="postulation.email" type="email" class="form-control form-control-sm" name="email" v-validate="'required'" data-vv-as="'Correo'" />
				<span v-show="errors.has('email')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('email') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="email" />
			</div>
			<!-- CELULAR -->
			<div class="col-md-6 form-group">
				<label class="col-form-label">Celular</label>
				<input v-model="postulation.cellphone" type="text" class="form-control form-control-sm" name="cellphone" v-validate="'required|numeric'" data-vv-as="'Celular'" />
				<span v-show="errors.has('cellphone')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('cellphone') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="cellphone" />
			</div>
			<!-- TELEFONO -->
			<div class="col-md-6 form-group">
				<label class="col-form-label">Telefono</label>
				<input v-model="postulation.telephone" name="telephone" type="text" class="form-control form-control-sm" v-validate="'numeric'" data-vv-as="'Telefono'" />
				<span v-show="errors.has('telephone')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('telephone') }} </span>
			</div>
			<!-- FUENTE -->
			<div class="col-md-12 form-group">
				<label class="col-form-label">Fuente</label>
				<v-select
					v-model="postulation.source_id"
					:options="requirements.sources"
					:reduce="source => source.id"
					label="name"
					name="source_id"
					v-validate="'required'"
					data-vv-as="'Fuente'"
					placeholder="Seleccionar" />
				<span v-show="errors.has('source_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('source_id') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="source_id" />
			</div>
		</div>
		<!-- EDITAR -->
		<div v-else-if="propInfo.data.method == 'edit'" class="row">
			<!-- TIPO DE DOC. -->
			<div class="col-sm-6 col-md-7">
				<label class="col-form-label">Tipo de Doc.</label>
				<p>{{ postulant.person.document_type_name }}</p>
			</div>
			<!-- NUMERO DE DOCUMENTO -->
			<div class="col-sm-6 col-md-5">
				<label class="col-form-label">Número de Doc.</label>
				<p>{{ postulant.person.document_number }}</p>
			</div>
			<!-- NOMBRES -->
			<div class="col-md-12">
				<label class="col-form-label">Nombres</label>
				<p>{{ postulant.person.names }}</p>
			</div>
			<!-- APELLIDO PATERNO -->
			<div class="col-md-6">
				<label class="col-form-label">Apellido paterno</label>
				<p>{{ postulant.person.father_last_name }}</p>
			</div>
			<!-- APELLIDO MATERNO -->
			<div class="col-md-6">
				<label class="col-form-label">Apellido materno</label>
				<p>{{ postulant.person.mother_last_name }}</p>
			</div>
			<!-- CORREO -->
			<div class="col-md-12 form-group">
				<label class="col-form-label">Correo</label>
				<input v-model="postulation.email" type="email" class="form-control form-control-sm" name="email" v-validate="'required'" data-vv-as="'Correo'" />
				<span v-show="errors.has('email')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('email') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="email" />
			</div>
			<!-- CELULAR -->
			<div class="col-md-6 form-group">
				<label class="col-form-label">Celular</label>
				<input v-model="postulation.cellphone" type="text" class="form-control form-control-sm" name="cellphone" v-validate="'required|numeric'" data-vv-as="'Celular'" />
				<span v-show="errors.has('cellphone')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('cellphone') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="cellphone" />
			</div>
			<!-- TELEFONO -->
			<div class="col-md-6 form-group">
				<label class="col-form-label">Telefono</label>
				<input v-model="postulation.telephone" name="telephone" type="text" class="form-control form-control-sm" v-validate="'numeric'" data-vv-as="'Telefono'" />
				<span v-show="errors.has('telephone')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('telephone') }} </span>
			</div>
			<!-- FUENTE -->
			<div class="col-md-12 form-group">
				<label class="col-form-label">Fuente</label>
				<v-select
					v-model="postulation.source_id"
					:options="requirements.sources"
					:reduce="source => source.id"
					label="name"
					name="source_id"
					v-validate="'required'"
					data-vv-as="'Fuente'"
					placeholder="Seleccionar" />
				<span v-show="errors.has('source_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('source_id') }} </span>
				<ErrorBackendComponent :errorsBackend="errorsBackend" name="source_id" />
			</div>
		</div>

		<!-- VER -->
		<div v-else-if="propInfo.data.method == 'view'" class="row">
			<!-- TIPO DE DOC. -->
			<div class="col-sm-6 col-md-7">
				<label class="col-form-label">Tipo de Doc.</label>
				<p>{{ postulant.person.document_type_name }}</p>
			</div>
			<!-- NUMERO DE DOCUMENTO -->
			<div class="col-sm-6 col-md-5">
				<label class="col-form-label">Número de Doc.</label>
				<p>{{ postulant.person.document_number }}</p>
			</div>
			<!-- NOMBRES -->
			<div class="col-md-12">
				<label class="col-form-label">Nombres</label>
				<p>{{ postulant.person.names }}</p>
			</div>
			<!-- APELLIDO PATERNO -->
			<div class="col-md-6">
				<label class="col-form-label">Apellido paterno</label>
				<p>{{ postulant.person.father_last_name }}</p>
			</div>
			<!-- APELLIDO MATERNO -->
			<div class="col-md-6">
				<label class="col-form-label">Apellido materno</label>
				<p>{{ postulant.person.mother_last_name }}</p>
			</div>
			<!-- CORREO -->
			<div class="col-md-12">
				<label class="col-form-label">Correo</label>
				<p>{{ postulation.email }}</p>
			</div>
			<!-- CELULAR -->
			<div class="col-md-6">
				<label class="col-form-label">Celular</label>
				<p>{{ postulation.cellphone }}</p>
			</div>
			<!-- TELEFONO -->
			<div class="col-md-6 form-group">
				<label class="col-form-label">Telefono</label>
				<p>{{ postulation.telephone }}</p>
			</div>
			<!-- FUENTE -->
			<div class="col-md-12">
				<label class="col-form-label">Fuente</label>
				<p>{{ postulation.source_name }}</p>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-md-12 text-right">
				<button @click="mtd_send" class="btn btn-primary btn-sm mr-2">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary btn-sm">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {
				document_types: [],
				sources: [],
			},
			filter: {
				document_type_id: null,
				document_number: null,
			},
			postulation: {
				requirement_id: null,
				source_id: null,
				source_name: null,
				email: null,
				telephone: null,
				cellphone: null,
			},
			postulant: {
				id: null,
				person: {
					id: null,
					document_type_id: null,
					document_type_name: null,
					document_number: null,
					names: null,
					father_last_name: null,
					mother_last_name: null,
				},
			},
			//Data base
			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
					view: 'this.mtd_onView()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},
	computed: {
		...mapGetters({
			url_api: 'url_api',
			requirement: 'requirements_state/requirement',
		}),
		cpd_btnFindPersonDisabled() {
			if (this.postulant.person.document_type_id == null) return true;
			if (this.postulant.person.document_number == null || !this.postulant.person.document_number.length) return true;
			return false;
		},
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onCreate() {
			this.mtd_createPostulant();
		},
		mtd_onEdit() {
			this.mtd_editPostulant();
		},
		mtd_onView() {
			this.mtd_showPostulant();
		},
		mtd_createPostulant() {
			this.get({ url: `${this.url_api}/postulant/create?requirement_id=${this.requirement.id}` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.document_types = res.document_types;
						this.requirements.sources = res.sources;
						this.$emit('onload', { status: 'success' });
					}
				})
				.catch(err => console.log(err));
		},
		mtd_editPostulant() {
			this.get({ url: `${this.url_api}/postulant/${this.propInfo.data.row.postulation_step_id}/edit?requirement_id=${this.requirement.id}` })
				.then(res => {
					if (res.state == 'success') {
						this.requirements.sources = res.sources;
						this.mtd_setPostulant(res.postulant);
						this.$emit('onload', { status: 'success' });
					}
				})
				.catch(err => console.log(err));
		},
		mtd_showPostulant() {
			this.get({ url: `${this.url_api}/postulant/${this.propInfo.data.row.postulation_step_id}/show?requirement_id=${this.requirement.id}` })
				.then(res => {
					if (res.state == 'success') {
						this.mtd_setPostulant(res.postulant);
						this.$emit('onload', { status: 'success' });
					}
				})
				.catch(err => console.log(err));
		},
		mtd_findPerson() {
			this.get({
				url: `${this.url_api}/postulant/find/person?requirement_id=${this.requirement.id}&document_type_id=${this.postulant.person.document_type_id}&document_number=${this.postulant.person.document_number}`,
			}).then(res => {
				switch (res.state) {
					case 'success':
						if (Object.prototype.hasOwnProperty.call(res, 'person')) {
							this.mtd_setPerson(res.person);
						} else if (Object.prototype.hasOwnProperty.call(res, 'postulant')) {
							this.mtd_setPostulant(res.postulant);
						}
						break;
					case 'error':
						window.message('warning', res.message);
						break;
					default:
						window.message('warning', 'Ups algo sucedió, vuelva a intentarlo');
						break;
				}
			});
		},
		mtd_setPostulant(postulant) {
			this.postulant.id = postulant.id;
			this.mtd_setPerson(postulant.person);
			this.mtd_setPostulation(postulant);
		},
		mtd_setPerson(person) {
			this.postulant.person.id = person.id;
			this.postulant.person.document_number = person.document_number;
			this.postulant.person.names = person.names;
			this.postulant.person.father_last_name = person.father_last_name;
			this.postulant.person.mother_last_name = person.mother_last_name;

			if (person.document_type_name != null) this.postulant.person.document_type_name = person.document_type_name;
		},
		mtd_setPostulation(postulant) {
			this.postulation.email = postulant.email;
			this.postulation.telephone = postulant.telephone;
			this.postulation.cellphone = postulant.cellphone;

			if (postulant.source_id != null) this.postulation.source_id = postulant.source_id;
			if (postulant.source_name != null) this.postulation.source_name = postulant.source_name;
		},
		mtd_store() {
			const params = {
				postulation: { ...this.postulation, requirement_id: this.requirement.id },
				postulant: this.postulant,
			};
			this.post({ url: `${this.url_api}/postulant/store`, params: params })
				.then(res => {
					if (res.state == 'success') {
						this.$emit('success', { component: this.propInfo.component, res: { postulant: res.postulant }, action: 'store', modal_id: this.modal_id });
						window.message('success', 'Registro exitoso');
					}
				})
				.catch(err => (this.errorsBackend = err));
		},
		mtd_update() {
			const params = {
				postulant: {
					email: this.postulation.email,
					telephone: this.postulation.telephone,
					cellphone: this.postulation.cellphone,
					source_id: this.postulation.source_id,
				},
			};
			this.update({ url: `${this.url_api}/postulant/${this.propInfo.data.row.postulation_step_id}/update`, params: params })
				.then(res => {
					if (res.state == 'success') {
						this.$emit('success', { component: this.propInfo.component, res: { postulant: res.postulant }, action: 'update', modal_id: this.modal_id });
						window.message('success', 'Actualización exitosa');
					}
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) _this.$eval(_this.methods.action[this.propInfo.data.method]);
			});
		},
	},
};
</script>
