<template>
	<div class="modal-body">
		<div class="timeline">
			<div class="tm-body">
				<template v-for="(step, index) in propInfo.data.row">
					<div class="tm-title" :key="'title_' + index">
						<h5 class="m-0 pt-2 pb-2 text-uppercase">{{ step.step_name }}</h5>
					</div>
					<ol v-if="step.step_code != 'FINISHED'" class="tm-items" :key="'items_' + index">
						<li v-for="(item, ir) in step.requirement_steps" :key="ir">
							<div class="tm-info">
								<div class="tm-icon" :class="item.current == 1 ? 'tm-icon-select' : ''">{{ item.group_name }}</div>
								<time class="tm-datetime">
									<div class="tm-datetime-date"><b>GRUPO</b></div>
								</time>
							</div>
							<div class="tm-box">
								<p><b>Fecha de inicio: </b>{{ item.created_at }}</p>
								<p><b>Fecha de finalización: </b>{{ item.finished_at ? item.finished_at : '---' }}</p>
							</div>
						</li>
					</ol>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
	},
	data() {
		return {
			errorsBackend: {},
			methods: {
				init: {
					view: 'this.mtd_onView()',
				},
				action: {
					view: 'this.mtd_detail()',
				},
			},
		};
	},
	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onView() {
			this.mtd_getShow();
		},
		mtd_getShow() {
			this.$emit('onload', { status: 'success' });
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close() {
			this.$emit('close');
		},
	},
};
</script>
