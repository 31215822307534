<template>
	<div>
		<!--NAVS	-->
		<nav>
			<div class="nav nav-tabs" id="nav-tab" role="tablist">
				<a
					v-for="(rs, i) in principal_interview.requirement_steps"
					:key="i"
					class="nav-link nav-link-step"
					:class="{ active: mtd_requirementStepIsActive(principal_interview.requirement_steps, rs) }"
					:id="`nav-group-${rs.id}-tab`"
					data-toggle="tab"
					:href="`#nav-group-${rs.id}`"
					role="tab"
					:aria-controls="`nav-group-${rs.id}`"
					:aria-selected="mtd_requirementStepIsActive(principal_interview.requirement_steps, rs)"
					>{{ `Grupo ${rs.group_name}` }}</a
				>
			</div>
		</nav>
		<div class="tab-content" id="nav-tabContent">
			<div
				v-for="(rs, i) in principal_interview.requirement_steps"
				:key="i"
				class="tab-pane fade pt-3"
				:class="{ 'show active': mtd_requirementStepIsActive(principal_interview.requirement_steps, rs) }"
				:id="`nav-group-${rs.id}`"
				role="tabpanel"
				:aria-labelledby="`nav-group-${rs.id}-tab`">
				<template v-if="rs.current == 1">
					<!-- ETAPA ACTUAL -->
					<div class="row mb-2">
						<div class="col-md-12">
							<h4>Postulantes</h4>
						</div>
					</div>
					<table class="table table-sm table-hover">
						<thead class="text-primary">
							<tr>
								<th class="text-center">#</th>
								<th v-if="authenticated_user.roles[0].code == 'REQUIREMENT_RESPONSABLE'" class="text-center">Progra. en bloque?</th>
								<th>Nombres</th>
								<th class="text-center">Fecha y hora de entrevista</th>
								<th class="text-center" width="100">Orden mérito</th>
								<th class="text-center">Acción</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(postulant, index) in rs.postulants" :key="index">
								<th class="text-center">{{ index + 1 }}</th>
								<!--PROGRAMAR EN GRUPO -->
								<template v-if="authenticated_user.roles[0].code == 'REQUIREMENT_RESPONSABLE'">
									<td class="text-center" v-if="postulant.interview_state == 0">
										<div class="form-check text-center">
											<input
												:value="postulant.postulation_step_id"
												v-model="selectedPostulants"
												:id="postulant.postulation_step_id"
												type="checkbox"
												class="form-check-input form-check-input" /><label :for="postulant.postulation_step_id" class="form-check-label"></label>
										</div>
									</td>
									<td class="text-center" v-else>---</td>
								</template>
								<!--APELLIDOS Y NOMBRES-->
								<td>
									<span class="cursor-pointer">{{ postulant.full_names }}</span> <span v-if="postulant.retired" class="badge badge-danger ml-3">Retirado</span>
								</td>
								<!--FECHA DE LA ENTRVISTA -->
								<td class="text-center" v-if="postulant.interview_state">{{ postulant.interview.scheduled_to }}</td>
								<td class="text-center" v-else>---</td>
								<td class="text-center" v-if="postulant.interview_state && postulant.retired == 0">
									<input
										autocomplete="off"
										@keypress="mtd_keyUpOrder($event)"
										:id="'orden_' + postulant.postulation_step_id"
										min="1"
										type="text"
										class="form-control form-control-sm"
										v-model="postulant.orden_merito"
										v-if="postulant.interview.result && authenticated_user.roles[0].code == 'REQUIREMENT_RESPONSABLE'" />

									<template v-else>
										<div>---</div>
									</template>
								</td>
								<td class="text-center" v-else>---</td>
								<!-- ACCIONES -->
								<td class="text-center" v-if="authenticated_user.roles[0].code == 'REQUIREMENT_RESPONSABLE'">
									<button v-if="!postulant.interview_state && postulant.retired == 0" @click="mtd_showProgrammingModal(postulant, i, index)" class="btn btn-xs btn-primary mx-2">
										<i class="far fa-calendar-alt"></i> Programación
									</button>
									<template v-if="postulant.interview_state">
										<button v-if="!postulant.interview.result" @click="mtd_showReprogrammingModal(postulant, i, index)" class="btn btn-xs btn-info mx-2">
											<i class="far fa-calendar-alt"></i> Reprogramación
										</button>
									</template>
									<button v-if="postulant.interview_state" @click="mtd_showProgrammingResultsModal(postulant, i, index)" class="btn btn-xs btn-warning mx-2">
										<i class="fas fa-marker"></i> Resultados
									</button>
									<button
										v-if="postulant.retired == 0"
										v-tooltip.left="`Retirar postulante`"
										@click="mtd_togglePostulant(i, index, postulant, 1)"
										class="btn btn-xs btn-danger mx-2">
										<i class="fas fa-user-slash"></i>
									</button>
									<button v-if="postulant.retired" v-tooltip.left="`Agregar postulante`" @click="mtd_togglePostulant(i, index, postulant, 0)" class="btn btn-xs btn-info mx-2">
										<i class="fas fa-user-plus"></i>
									</button>
								</td>
								<td class="text-center" v-else>
									<div v-if="postulant.interview_state && postulant.interview.result">
										<button v-if="postulant.interview_state" @click="mtd_showProgrammingResultsModal(postulant, i, index)" class="btn btn-xs btn-warning mx-2">
											<i class="fas fa-marker"></i> Resultados
										</button>
									</div>
									<div v-else>---</div>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="row mt-4" v-if="authenticated_user.roles[0].code == 'REQUIREMENT_RESPONSABLE' && requirement.state != 2">
						<div class="col-md-12 text-center">
							<button @click="mtd_programarBloque" :disabled="selectedPostulants.length == 0" class="btn btn-sm btn-secondary mr-2">
								<i class="far fa-calendar-alt"></i> Programar en bloque
							</button>
							<button :disabled="cp_new_group" @click="mtd_newGroup" class="btn btn-sm btn-info mr-2"><i class="fas fa-plus"></i> Nuevo grupo</button>
							<button :disabled="!cp_new_group" @click="mtd_nextStep" class="btn btn-sm btn-primary">Ir a Examen medico <i class="fas fa-arrow-circle-right"></i></button>
						</div>
					</div>
				</template>
				<template v-else>
					<!-- ETAPA PASADA -->
					<div class="row mb-2">
						<div class="col-md-12">
							<h4>Postulantes</h4>
						</div>
					</div>
					<table class="table table-sm table-hover">
						<thead class="text-primary">
							<tr>
								<th class="text-center">#</th>
								<th>Nombres</th>
								<th class="text-center">Fecha y hora de entrevista</th>
								<th class="text-center" width="100">Orden mérito</th>
								<th class="text-center">Pasó a la sig. etapa?</th>
								<th class="text-center">Acción</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(postulant, index) in rs.postulants" :key="index">
								<th class="text-center">{{ index + 1 }}</th>
								<td>
									<span class="cursor-pointer">{{ postulant.full_names }}</span> <span v-if="postulant.retired" class="badge badge-danger ml-3">Retirado</span>
								</td>
								<td class="text-center" v-if="postulant.interview_state">{{ postulant.interview.scheduled_to }}</td>
								<td class="text-center" v-else>---</td>
								<td class="text-center" v-if="postulant.interview_state && postulant.retired == 0">
									<div v-if="postulant.selected">
										{{ postulant.interview.merit_order }}
									</div>
									<div v-else>---</div>
								</td>
								<td class="text-center" v-else>---</td>
								<td class="text-center" v-if="postulant.interview_state && postulant.retired == 0">
									<i v-if="postulant.selected" class="fas fa-check-square text-success cursor-pointer" style="font-size: 20px"></i>
									<i v-else class="fas fa-window-close text-danger cursor-pointer" style="font-size: 20px"></i>
								</td>
								<td class="text-center" v-else>---</td>
								<!-- ACCIONES -->
								<td class="text-center">
									<div v-if="postulant.interview_state && postulant.interview.result">
										<button v-if="postulant.interview_state" @click="mtd_showProgrammingResultsModal(postulant, i, index)" class="btn btn-xs btn-warning mx-2">
											<i class="fas fa-marker"></i> Resultados
										</button>
									</div>
									<div v-else>---</div>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</div>
		</div>
		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'principal-interview-programming-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
			<template v-else-if="modal.id == 'principal-interview-programming-results-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
			<template v-else-if="modal.id == 'principal-programming-block-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import PrincipalInterviewProgrammingModal from './components/Programming';
import PrincipalInterviewProgrammingResultsModal from './components/ProgrammingResults';
import PrincipalProgrammingBlockModal from './components/ProgrammingBlock';
export default {
	components: {
		PrincipalInterviewProgrammingModal,
		PrincipalInterviewProgrammingResultsModal,
		PrincipalProgrammingBlockModal,
	},
	props: ['propInfo'],
	data() {
		return {
			selectedPostulants: [],
			modals: [
				{
					id: 'principal-interview-programming-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'principal-interview-programming-modal',
						idHash: '#principal-interview-programming-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
				{
					id: 'principal-interview-programming-results-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'principal-interview-programming-results-modal',
						idHash: '#principal-interview-programming-results-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
				//PROGRAMAR EN BLOQUE
				{
					id: 'principal-programming-block-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'principal-programming-block-modal',
						idHash: '#principal-programming-block-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	created() {},
	computed: {
		...mapGetters({
			url_api: 'url_api',
			authenticated_user: 'authenticated_user',
			requirement: 'requirements_state/requirement',
			principal_interview: 'requirements_state/principal_interview',
			isEditable: 'requirements_state/isEditable',
			steps: 'requirements_state/steps',
		}),
		cp_new_group() {
			let state = true;
			let count = 0;
			let postulants = this.principal_interview.requirement_steps.find(el => el.current == 1).postulants;
			postulants.forEach(postulant => {
				if (postulant.retired == 1) {
					count++;
				}
			});
			if (count == postulants.length) {
				state = false;
			}
			return state;
		},
	},
	methods: {
		...mapActions(['get', 'post', 'update', 'execute_commit']),
		// eslint-disable-next-line no-unused-vars
		mtd_componentSuccess: function ({ component, data, action, modal_id }) {
			switch (component) {
				case 'principal-interview-programming-modal':
					this.mtd_closeModal(modal_id);
					this.update_postulant(data, 1);
					break;
				case 'principal-interview-programming-results-modal':
					this.mtd_closeModal(modal_id);
					this.update_postulant(data, 2);
					break;
				case 'principal-programming-block-modal':
					this.$emit('emit-show-state');
					this.selectedPostulants = [];
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},
		update_postulant: function (data, state) {
			if (state == 1) {
				this.principal_interview.requirement_steps[data.i].postulants[data.index].interview_state = 1;
			}
			this.principal_interview.requirement_steps[data.i].postulants[data.index].interview = data.interview;
		},
		mtd_programarBloque: function () {
			const main_form = this.modals.find(el => el.id == 'principal-programming-block-modal');
			main_form.slot.component = 'principal-programming-block-modal';
			main_form.slot.data = {
				row: this.selectedPostulants,
				method: 'definition',
				buttonSubmit: 'Guardar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-center';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Programación en bloque de la Entrevista Principal";
		},

		mtd_showProgrammingModal(row, i, index) {
			const main_form = this.modals.find(el => el.id == 'principal-interview-programming-modal');

			main_form.slot.component = 'principal-interview-programming-modal';
			main_form.slot.data = {
				row: row,
				index: index,
				i: i,
				method: 'definition',
				buttonSubmit: 'Guardar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Programación de la Entrevista Principal";
		},
		mtd_showReprogrammingModal(row, i, index) {
			const main_form = this.modals.find(el => el.id == 'principal-interview-programming-modal');

			main_form.slot.component = 'principal-interview-programming-modal';
			main_form.slot.data = {
				row: row,
				index: index,
				i: i,
				method: 'update',
				buttonSubmit: 'Actualizar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Reprogramación de la Entrevista Principal";
		},
		mtd_showProgrammingResultsModal(row, i, index) {
			const main_form = this.modals.find(el => el.id == 'principal-interview-programming-results-modal');
			main_form.slot.component = 'principal-interview-programming-results-modal';
			main_form.slot.data = {
				row: row,
				index: index,
				i: i,
				method: 'definition',
				buttonSubmit: 'Guardar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Resultados de la Entrevista Principal";
		},

		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		mtd_requirementStepIsActive(requirement_steps, rs) {
			if (requirement_steps.find(el => el.current == 1)) {
				return rs.current == 1 ? true : false;
			} else {
				return requirement_steps[requirement_steps.length - 1].id == rs.id ? true : false;
			}
		},
		mtd_togglePostulant(i, index, row, state) {
			let message = '';
			let button = '';
			let message_modal = '';
			if (state == 1) {
				message = '¿Esta seguro de retirar a este postulante?';
				message_modal = 'Retirado correctamente.';
				button = 'Si, retirar';
			}
			if (state == 0) {
				message = '¿Esta seguro que desea agregar a este postulante?';
				message_modal = 'Agregado correctamente.';
				button = 'Si, agregar';
			}
			this.$swal
				.fire({
					title: message,
					text: `${row.full_names}`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#e74c3c',
					confirmButtonText: button,
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({ url: `${this.url_api}/postulant/${row.postulation_step_id}/evaluation/retired`, params: { state: state } }).then(res => {
							if (res.state == 'success') {
								this.principal_interview.requirement_steps[i].postulants[index].retired = state;
								window.message('success', message_modal);
							}
						});
					}
				});
		},
		mtd_keyUpOrder: function (evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (charCode > 31 && (charCode < 49 || charCode > 57) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		mtd_nextStep() {
			let postulants = this.principal_interview.requirement_steps.find(el => el.current == 1).postulants;
			let puestos = [];
			let band_vacios = 0;
			postulants.forEach(postulant => {
				var element = document.getElementById('orden_' + postulant.postulation_step_id);
				if (postulant.orden_merito) {
					element.classList.remove('input-error-puesto');
					puestos.push(postulant.orden_merito);
				} else {
					element.classList.add('input-error-puesto');
					band_vacios = band_vacios + 1;
					window.simplealert('warning', 'Campo vacio, por favor ingresar ORDEN DE MERITO.', '<i class="fas fa-sticky-note"></i> Mensaje');
				}
			});

			let band_duplicados = 0;
			if (band_vacios == 0) {
				/////// BUSCAR DUPLICADOS
				let duplicados = [];

				const tempArray = [...puestos].sort();

				for (let i = 0; i < puestos.length; i++) {
					if (tempArray[i + 1] === tempArray[i]) {
						duplicados.push(tempArray[i]);
					}
				}
				if (duplicados.length > 0) {
					band_duplicados = band_duplicados + 1;
					window.simplealert('warning', 'Los puestos [' + duplicados + '] estan duplicados, por favor verificar.', '<i class="fas fa-sticky-note"></i> Mensaje');
				}
			}

			let band_orden = 0;
			if (band_duplicados == 0 && band_vacios == 0) {
				let puestos_faltantes = [];
				//RECORRER LOS PUESTOS (CANTIDAD POSTULANTES)
				for (let index = 0; index < postulants.length; index++) {
					let search = puestos.indexOf('' + (index + 1) + '');
					if (search != '-1') {
						//
					} else {
						puestos_faltantes.push(index + 1);
					}
				}
				if (puestos_faltantes.length > 0) {
					band_orden = band_orden + 1;
					window.simplealert('warning', 'Los puestos [' + puestos_faltantes + '] no existen, por favor ingresar.', '<i class="fas fa-sticky-note"></i> Mensaje');
				}
			}

			if (band_duplicados == 0 && band_vacios == 0 && band_orden == 0) {
				this.$swal
					.fire({
						icon: 'question',
						title: '¿Estás seguro de pasar a la etapa de "Examen medico"?',
						showCancelButton: true,
						confirmButtonText: 'Sí, confirmar',
					})
					.then(result => {
						if (result.isConfirmed) {
							this.post({
								url: `${this.url_api}/requirement/${this.requirement.id}/next_step_principal_entrevista`,
								params: { postulants: postulants },
							}).then(res => {
								if (res.state == 'success') {
									this.$swal.fire({
										icon: 'success',
										title: `El Requerimiento pasó a la etapa de 'Examen medico'`,
										showCancelButton: false,
									});
									this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
									this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
								}
								if (res.state == 'exception') {
									window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
								}
							});
						}
					});
			}
		},
		mtd_newGroup: function () {
			this.$swal
				.fire({
					icon: 'question',
					title: '¿Estás seguro que desea crear otro grupo?',
					showCancelButton: true,
					confirmButtonText: 'Sí, confirmar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/requirement/${this.requirement.id}/restart`,
							params: {},
						}).then(res => {
							if (res.state == 'success') {
								this.$swal.fire({
									icon: 'success',
									title: `Grupo creado correctamente`,
									showCancelButton: false,
								});
								this.execute_commit({ mutation: 'requirements_state/REQUIREMENT__SET_ALL', payload: res.requirement });
								this.execute_commit({ mutation: 'requirements_state/STEPS__SET_ALL', payload: res.steps });
							}
							if (res.state == 'exception') {
								window.simplealert('warning', '' + res.message + '', '<i class="fas fa-sticky-note"></i> Mensaje');
							}
						});
					}
				});
		},
	},
};
</script>
