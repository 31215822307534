<template>
	<div class="modal-body">
		<!-- CREAR -->
		<div v-if="propInfo.data.method == 'definition'" class="row">
			<!-- DATOS DEL POSTULANTE -->
			<div class="col-md-12">
				<h5>Datos del postulante</h5>
			</div>
			<!-- TIPO DE DOC. -->
			<div class="col-md-6">
				<label class="col-form-label label-modal" for="business_id">Tipo de Doc.</label>
				<p class="mb-0">{{ propInfo.data.row.document_type_name }}</p>
			</div>
			<!-- NUMERO DE DOCUMENTO -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Número de Doc.</label>
				<p class="mb-0">{{ propInfo.data.row.document_number }}</p>
			</div>
			<!-- NOMBRES COMPLETOS -->
			<div class="col-md-12">
				<label class="col-form-label label-modal">Nombre</label>
				<p class="mb-0">{{ propInfo.data.row.full_names }}</p>
			</div>
			<!-- CELULAR -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Celular</label>
				<p class="mb-0">{{ propInfo.data.row.cellphone }}</p>
			</div>
			<!-- EMAIL -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Correo</label>
				<p class="mb-0">{{ propInfo.data.row.email }}</p>
			</div>
			<!------------ DATOS DEL EXAMEN ------------->
			<div class="col-md-12 mt-3">
				<h5>Datos del examen medico</h5>
			</div>
			<div class="col-md-6">
				<label class="col-form-label label-modal">Tipo de examen</label>
				<p class="mb-0">{{ propInfo.data.row.exam.exam_type_name }}</p>
			</div>
			<div class="col-md-6">
				<label class="col-form-label label-modal">Centro medico</label>
				<p class="mb-0">{{ propInfo.data.row.exam.place_name }}</p>
			</div>
			<div class="col-md-6">
				<label class="col-form-label label-modal">Fecha de la cita</label>
				<p class="mb-0">{{ propInfo.data.row.exam.scheduled_to }}</p>
			</div>
			<div class="col-md-6">
				<label class="col-form-label label-modal">Prueba antigena</label>
				<p class="mb-0">{{ propInfo.data.row.exam.antigen ? 'Si' : 'No' }}</p>
			</div>
			<!----------- RESULTADOS ------->
			<div class="col-md-12 mt-3">
				<h5>Resultados medicos</h5>
			</div>
			<!-- ESTADO -->
			<div class="col-md-6">
				<label class="col-form-label">Estado</label>
				<p class="mb-0">
					<span v-if="propInfo.data.row.exam.suitable == 1" class="badge badge-success" style="font-size: 13px">Apto</span>
					<span v-if="propInfo.data.row.exam.suitable == 2" class="badge badge-warning" style="font-size: 13px">Apto con recomendaciones</span>
					<span v-if="propInfo.data.row.exam.suitable == 0" class="badge badge-danger" style="font-size: 13px">Apto</span>
				</p>
			</div>
			<!-- RESULTADOS -->
			<div class="col-md-6">
				<label class="col-form-label d-block">Resultados</label>
				<button @click="mtd_donload" class="btn btn-xs btn-primary"><i class="fas fa-file-pdf"></i> Descargar</button>
			</div>
			<!-- ESTADO ANTIGENA-->
			<div class="col-md-6" v-if="propInfo.data.row.exam.antigen">
				<label class="col-form-label">Resultado prueba antigena</label>
				<p class="mb-0">
					<span v-if="propInfo.data.row.exam.state_antigena == 0" class="badge badge-success" style="font-size: 13px">Negativo</span>
					<span v-if="propInfo.data.row.exam.state_antigena == 1" class="badge badge-danger" style="font-size: 13px">Positivo</span>
				</p>
			</div>
			<!-- RESULTADO PRUEBA ANTIGENA-->
			<div class="col-md-6" v-if="propInfo.data.row.exam.antigen">
				<label class="col-form-label d-block">Certificado prueba antigena</label>
				<button @click="mtd_donload_antigena" class="btn btn-xs btn-primary"><i class="fas fa-file-pdf"></i> Descargar</button>
			</div>
			<!-- OBSERVACIONES -->
			<div class="col-md-12" v-if="propInfo.data.row.exam.suitable == 2">
				<label class="col-form-label">Recomendaciones</label>
				<p class="mb-0" v-html="propInfo.data.row.exam.observation"></p>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_close" class="btn btn-secondary">Cerrar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			requirements: {},

			errorsBackend: {},
			methods: {
				init: {
					definition: 'this.mtd_onDefinition()',
				},
				action: {
					definition: 'this.mtd_store()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api', 'url_web']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onDefinition() {
			this.mtd_definition();
		},
		mtd_definition() {
			this.$emit('onload', { status: 'success' });
		},
		mtd_store: function () {
			// this.post({
			// 	url: `${this.url_api}/job_position/store`,
			// 	params: { job_position: this.job_position },
			// })
			// 	.then(res => {
			// 		if (res.state == 'success') {
			// 			this.$emit('success', { component: this.propInfo.component, res: { job_position: res.job_position }, action: 'store', modal_id: this.modal_id });
			// 			window.message('success', 'Registro exitoso');
			// 		}
			// 	})
			// 	.catch(errors => (this.errorsBackend = errors));
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
		mtd_donload: function () {
			window.open(`${this.url_web}/uploads/medicals/${this.propInfo.data.row.exam.file}.pdf`);
		},
		mtd_donload_antigena: function () {
			window.open(`${this.url_web}/uploads/medicals/${this.propInfo.data.row.exam.file_antigena}.pdf`);
		},
	},
};
</script>
